import { AppLogger } from '@/logger';
import {
  CreateSupportRequestDto,
  SupportRequestCategoryDto,
  SupportRequestDepartmentDto,
  SupportRequestSubcategoryDto,
  SupportRequestTypeDto,
} from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { getErrorMessage, getSharedFileApi, getSupportRequestApi } from '@/castapi/helpers';
import { IActionParams } from '@/store/modules/index';
import axios from 'axios';
import { uid } from 'uid';

const logger = new AppLogger('support state');

interface SupportRequestFile {
  fileId: string;
  fileName: string;
}

const getSseEventSource = (accessToken: string, clientId: string) =>
  new EventSource(`${apiConfig.basePath}/api/share-file/events?token=${accessToken}&id=${clientId}`);

interface ISupportRequestState {
  isLoading: boolean;
  error: string | null;
  supportRequestTypes: SupportRequestTypeDto[];
  supportRequestDepartments: SupportRequestDepartmentDto[];
  supportRequestCategories: SupportRequestCategoryDto[];
  supportRequestSubcategories: SupportRequestSubcategoryDto[];
  shareFileUploadLink: null | string;
  supportRequestFiles: SupportRequestFile[];
  eventSource: null | EventSource;
}

const initialState = (): ISupportRequestState => ({
  isLoading: false,
  error: null,
  supportRequestTypes: [],
  supportRequestDepartments: [],
  supportRequestCategories: [],
  supportRequestSubcategories: [],
  shareFileUploadLink: null,
  supportRequestFiles: [],
  eventSource: null,
});

type ActionParams = IActionParams<ISupportRequestState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RESET_STATE(state: ISupportRequestState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
    ERROR(state: ISupportRequestState, error: Error): void {
      state.error = getErrorMessage(error);
      state.isLoading = false;
    },
    SUPPORT_REQUEST_TYPES_LOADING(state: ISupportRequestState): void {
      state.error = null;
      state.isLoading = true;
      state.supportRequestTypes = [];
    },
    SUPPORT_REQUEST_DEPARTMENTS_LOADING(state: ISupportRequestState): void {
      state.error = null;
      state.isLoading = true;
      state.supportRequestDepartments = [];
    },
    SUPPORT_REQUEST_CATEGORIES_LOADING(state: ISupportRequestState): void {
      state.error = null;
      state.isLoading = true;
      state.supportRequestCategories = [];
    },
    SUPPORT_REQUEST_SUBCATEGORIES_LOADING(state: ISupportRequestState): void {
      state.error = null;
      state.isLoading = true;
      state.supportRequestSubcategories = [];
    },
    SUPPORT_REQUEST_TYPES_LOADED(state: ISupportRequestState, payload: SupportRequestTypeDto[]): void {
      state.isLoading = false;
      state.supportRequestTypes = payload;
    },
    SUPPORT_REQUEST_DEPARTMENTS_LOADED(state: ISupportRequestState, payload: SupportRequestDepartmentDto[]): void {
      state.isLoading = false;
      state.supportRequestDepartments = payload;
    },
    SUPPORT_REQUEST_CATEGORIES_LOADED(state: ISupportRequestState, payload: SupportRequestCategoryDto[]): void {
      state.isLoading = false;
      state.supportRequestCategories = payload;
    },
    SUPPORT_REQUEST_SUBCATEGORIES_LOADED(state: ISupportRequestState, payload: SupportRequestSubcategoryDto[]): void {
      state.isLoading = false;
      state.supportRequestSubcategories = payload;
    },
    SUPPORT_REQUEST_CREATING(state: ISupportRequestState): void {
      state.error = null;
      state.isLoading = true;
    },
    SUPPORT_REQUEST_CREATED(state: ISupportRequestState): void {
      state.isLoading = false;
    },
    SHARE_FILE_UPLOAD_LINK_CREATING(state: ISupportRequestState): void {
      state.shareFileUploadLink = null;
      state.error = null;
      state.isLoading = true;
    },
    SHARE_FILE_UPLOAD_LINK_CREATED(state: ISupportRequestState, link: string): void {
      state.isLoading = false;
      state.shareFileUploadLink = link;
    },
    SUPPORT_REQUEST_FILE_DELETING(state: ISupportRequestState): void {
      state.isLoading = true;
    },
    SUPPORT_REQUEST_FILE_DELETED(state: ISupportRequestState, fileId: string): void {
      state.isLoading = false;
      state.supportRequestFiles = state.supportRequestFiles.filter(file => file.fileId !== fileId);
    },
    HANDLE_MESSAGE(state: ISupportRequestState, data: { fileId; fileName; operationName }): void {
      if (data.operationName === 'Upload') {
        const { fileId, fileName } = data;
        state.supportRequestFiles.push({ fileId, fileName });
      } else if (data.operationName === 'Delete') {
        const { fileId } = data;
        state.supportRequestFiles = state.supportRequestFiles.filter(file => file.fileId !== fileId);
      }
    },
    SSE_CONNECTED(state: ISupportRequestState, eventSource: EventSource): void {
      state.eventSource = eventSource;
    },
    SSE_DISCONNECTED(state: ISupportRequestState): void {
      state.eventSource = null;
    },
  },
  actions: {
    async loadSupportRequestTypes({ commit, rootGetters }: ActionParams): Promise<void> {
      try {
        commit('SUPPORT_REQUEST_TYPES_LOADING');
        const accessToken = rootGetters['login/accessToken'];
        const response = await getSupportRequestApi(accessToken).supportRequestControllerGetSupportRequestTypes();
        commit('SUPPORT_REQUEST_TYPES_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('loadSupportRequestTypes', error);
      }
    },

    async loadSupportRequestDepartments({ commit, rootGetters }: ActionParams): Promise<void> {
      try {
        commit('SUPPORT_REQUEST_DEPARTMENTS_LOADING');
        const accessToken = rootGetters['login/accessToken'];
        const response = await getSupportRequestApi(accessToken).supportRequestControllerGetSupportRequestDepartments();
        commit('SUPPORT_REQUEST_DEPARTMENTS_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('loadSupportRequestDepartments', error);
      }
    },

    async loadSupportRequestCategories({ commit, rootGetters }: ActionParams): Promise<void> {
      try {
        commit('SUPPORT_REQUEST_CATEGORIES_LOADING');
        const accessToken = rootGetters['login/accessToken'];
        const response = await getSupportRequestApi(accessToken).supportRequestControllerGetSupportRequestCategories();
        commit('SUPPORT_REQUEST_CATEGORIES_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('loadSupportRequestCategories', error);
      }
    },

    async loadSupportRequestSubcategories({ commit, rootGetters }: ActionParams): Promise<void> {
      try {
        commit('SUPPORT_REQUEST_SUBCATEGORIES_LOADING');
        const accessToken = rootGetters['login/accessToken'];
        const response = await getSupportRequestApi(
          accessToken,
        ).supportRequestControllerGetSupportRequestSubcategories();
        commit('SUPPORT_REQUEST_SUBCATEGORIES_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('loadSupportRequestSubcategories', error);
      }
    },

    async createSupportRequest({ commit, rootGetters }: ActionParams, request: CreateSupportRequestDto): Promise<void> {
      try {
        commit('SUPPORT_REQUEST_CREATING');
        const accessToken = rootGetters['login/accessToken'];
        await getSupportRequestApi(accessToken).supportRequestControllerCreateSupportRequest(request);
        commit('SUPPORT_REQUEST_CREATED');
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('createSupportRequest', error);
      }
    },

    async uploadFileToFileShare(
      { commit, rootGetters }: ActionParams,
      { file, path }: { file: File; path: string },
    ): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      const chunkSize = 5 * 1024 * 1024; // 5MB per chunk
      const totalChunks = Math.ceil(file.size / chunkSize);
      let currentChunk = 0;
      const fileId = uid(16);
      commit('SUPPORT_REQUEST_FILE_LOADING');
      let uploadResponse;
      while (currentChunk < totalChunks) {
        const start = currentChunk * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        const chunk = file.slice(start, end);
        const formData = new FormData();
        formData.append('fileName', path);
        formData.append('fileSize', file.size.toString());
        formData.append('currentChunk', `${currentChunk}`);
        formData.append('totalChunks', `${totalChunks}`);
        formData.append('chunk', chunk);
        formData.append('fileId', fileId);
        try {
          // eslint-disable-next-line no-await-in-loop -- I camn assume that we can't do this in parallel
          uploadResponse = await axios.post(`${apiConfig.basePath}/api/share-file/upload-file`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${accessToken}`,
            },
          });
          currentChunk++;
          const progress = Math.floor((currentChunk / totalChunks) * 100);
          commit('SUPPORT_REQUEST_FILE_LOAD_PROGRESS', progress);
        } catch (error) {
          commit('ERROR', error);
          logger.captureStoreError('uploadFileToFileShare', error);
          return;
        }
      }
      commit('SUPPORT_REQUEST_FILE_LOADED', uploadResponse.data);
    },

    async getShareFileUploadLink({ commit, rootGetters }: ActionParams, parentFolderName: string): Promise<void> {
      commit('SHARE_FILE_UPLOAD_LINK_CREATING');
      try {
        const accessToken = rootGetters['login/accessToken'];
        const response = await getSharedFileApi(accessToken).shareFileControllerGetUploadLink(parentFolderName);
        commit('SHARE_FILE_UPLOAD_LINK_CREATED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getShareFileUploadLink', error);
      }
    },

    async deleteFileFromFileShare({ commit, rootGetters }: ActionParams, fileId: string): Promise<void> {
      try {
        commit('SUPPORT_REQUEST_FILE_DELETING');
        const accessToken = rootGetters['login/accessToken'];
        await getSharedFileApi(accessToken).shareFileControllerDeleteFile({ fileId });
        commit('SUPPORT_REQUEST_FILE_DELETED', fileId);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('deleteFileFromFileShare', error);
      }
    },

    connectToSSE({ rootGetters, commit, dispatch }: ActionParams, clientId: string): void {
      const accessToken = rootGetters['login/accessToken'];
      const eventSource = getSseEventSource(accessToken, clientId);
      eventSource.onmessage = event => {
        const data = JSON.parse(event.data);
        commit('HANDLE_MESSAGE', data);
      };
      eventSource.onerror = () => {
        eventSource.close();
        setTimeout(() => dispatch('connectToSSE', clientId), 30000);
      };
      commit('SSE_CONNECTED', eventSource);
    },

    disconnectFromSSE({ state, commit }: ActionParams): void {
      if (state.eventSource) {
        state.eventSource.close();
      }
      commit('SSE_DISCONNECTED');
    },
  },
  getters: {
    isLoading: (state: ISupportRequestState): boolean => state.isLoading,
    error: (state: ISupportRequestState): string | null => state.error,
    supportRequestTypes: (state: ISupportRequestState): SupportRequestTypeDto[] => state.supportRequestTypes,
    supportRequestDepartments: (state: ISupportRequestState): SupportRequestDepartmentDto[] =>
      state.supportRequestDepartments,
    supportRequestCategories: (state: ISupportRequestState): SupportRequestCategoryDto[] =>
      state.supportRequestCategories,
    supportRequestSubcategories: (state: ISupportRequestState): SupportRequestSubcategoryDto[] =>
      state.supportRequestSubcategories,
    shareFileUploadLink: (state: ISupportRequestState): string | null => state.shareFileUploadLink,
    supportRequestFiles: (state: ISupportRequestState): SupportRequestFile[] => state.supportRequestFiles,
  },
};
