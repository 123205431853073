import { default as defaultStore } from '@/store';

interface MessageOptions {
  message: string;
  color?: string;
  topAndCentered?: boolean;
  timeout?: number;
}

const snackbarPlugin = {
  showMessage: (message: string): void => {
    defaultStore.commit('snackbar/SHOW_MESSAGE', { message }, { root: true });
  },
  showMessageExt: ({ message, color = undefined, topAndCentered = false, timeout = 3 }: MessageOptions): void => {
    defaultStore.commit('snackbar/SHOW_MESSAGE', { message, color, topAndCentered, timeout }, { root: true });
  },
  success: (message: string, timeout?: number): void => {
    defaultStore.commit('snackbar/SHOW_MESSAGE', { message, color: 'success', timeout }, { root: true });
  },
  warn: (message: string, timeout?: number): void => {
    defaultStore.commit('snackbar/SHOW_MESSAGE', { message, color: 'warning', timeout }, { root: true });
  },
  error: (message: string, timeout?: number): void => {
    defaultStore.commit('snackbar/SHOW_MESSAGE', { message, color: 'error', timeout }, { root: true });
  },
  close: (): void => {
    defaultStore.commit('snackbar/CLOSE', undefined, { root: true });
  },
  install: (
    Vue: { prototype: { $snackbar: typeof snackbarPlugin } },
    {
      store,
    }: {
      store: typeof defaultStore;
    },
  ): void => {
    if (!store) {
      throw new Error('Please provide vuex store.');
    }
    // noinspection JSUnusedGlobalSymbols
    Vue.prototype.$snackbar = snackbarPlugin;
  },
};

export default snackbarPlugin;
