import { DiscountDto, DiscountProductTypeDto } from '@/castapi';
import { AppLogger } from '@/logger';
import { getDiscountApi, getErrorMessage } from '@/castapi/helpers';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('adminDiscounts state');

interface IAdminDiscountsState {
  discounts: DiscountDto[];
  discountsLoading: boolean;
  discountsLoadError: null | string;
  discountProductTypes: DiscountProductTypeDto[];
  discountProductTypesLoading: boolean;
  discountProductTypesLoadError: null | string;
  discountSaving: boolean;
  discountSaveError: null | string;
}

const initialState = (): IAdminDiscountsState => ({
  discounts: [],
  discountsLoading: false,
  discountsLoadError: null,
  discountProductTypes: [],
  discountProductTypesLoading: false,
  discountProductTypesLoadError: null,
  discountSaving: false,
  discountSaveError: null,
});

type ActionParams = IActionParams<IAdminDiscountsState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    DISCOUNTS_LOADING(state: IAdminDiscountsState): void {
      state.discountsLoadError = null;
      state.discountsLoading = true;
    },
    DISCOUNTS_LOADED(state: IAdminDiscountsState, payload: DiscountDto[]): void {
      state.discounts = payload;
      state.discountsLoading = false;
    },
    DISCOUNTS_LOAD_ERROR(state: IAdminDiscountsState, error: Error): void {
      state.discountsLoadError = getErrorMessage(error);
      state.discountsLoading = false;
    },
    DISCOUNT_TYPES_LOADING(state: IAdminDiscountsState): void {
      state.discountProductTypesLoadError = null;
      state.discountProductTypesLoading = true;
    },
    DISCOUNT_TYPES_LOADED(state: IAdminDiscountsState, payload: DiscountProductTypeDto[]): void {
      state.discountProductTypes = payload;
      state.discountProductTypesLoading = false;
    },
    DISCOUNT_TYPES_LOAD_ERROR(state: IAdminDiscountsState, error: Error): void {
      state.discountProductTypesLoadError = getErrorMessage(error);
      state.discountProductTypesLoading = false;
    },
    DISCOUNT_SAVING(state: IAdminDiscountsState): void {
      state.discountSaveError = null;
      state.discountSaving = true;
    },
    DISCOUNT_SAVED(state: IAdminDiscountsState): void {
      state.discountSaveError = null;
      state.discountSaving = false;
    },
    DISCOUNT_SAVE_ERROR(state: IAdminDiscountsState, error: Error): void {
      state.discountSaveError = getErrorMessage(error);
      state.discountSaving = false;
    },
    RESET_STATE(state: IAdminDiscountsState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    async loadDiscounts({ commit, rootGetters }: ActionParams): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DISCOUNTS_LOADING');
        const response = await getDiscountApi(accessToken).discountsControllerFindAll();
        commit('DISCOUNTS_LOADED', response.data);
      } catch (error) {
        commit('DISCOUNTS_LOAD_ERROR', error);
        logger.captureStoreError('loadDiscounts', error);
      }
    },
    async loadAvailableDiscounts(
      { commit, rootGetters }: ActionParams,
      { orderId, userId, dealerPurchaseRequest }: { orderId: number; userId: number; dealerPurchaseRequest: boolean },
    ): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DISCOUNTS_LOADING');
        const response = await getDiscountApi(accessToken).discountsControllerFindAvailable(
          userId,
          orderId,
          dealerPurchaseRequest,
        );
        commit('DISCOUNTS_LOADED', response.data);
      } catch (error) {
        commit('DISCOUNTS_LOAD_ERROR', error);
        logger.captureStoreError('loadAvailableDiscounts', error);
      }
    },
    async loadDiscountTypes({ commit, rootGetters }: ActionParams): Promise<void> {
      try {
        commit('DISCOUNT_TYPES_LOADING');
        const res = await getDiscountApi(rootGetters['login/accessToken']).discountsControllerGetDiscountProductTypes();
        commit('DISCOUNT_TYPES_LOADED', res.data);
      } catch (error) {
        commit('DISCOUNT_TYPES_LOAD_ERROR', error);
        logger.captureStoreError('loadDiscountTypes', error);
      }
    },
    async addDiscount({ commit, rootGetters }: ActionParams, data: DiscountDto): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      commit('DISCOUNT_SAVING');
      try {
        await getDiscountApi(accessToken).discountsControllerCreate(data);
        commit('DISCOUNT_SAVED');
      } catch (error) {
        commit('DISCOUNT_SAVE_ERROR', error);
        logger.captureStoreError('addDiscount', error, data);
      }
    },
    async updateDiscount({ commit, rootGetters }: ActionParams, data: DiscountDto): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      commit('DISCOUNT_SAVING');
      try {
        await getDiscountApi(accessToken).discountsControllerUpdate(data.discountId, data);
        commit('DISCOUNT_SAVED');
      } catch (error) {
        commit('DISCOUNT_SAVE_ERROR', error);
        logger.captureStoreError('updateDiscount', error, data);
      }
    },
    async removeDiscount({ commit, rootGetters }: ActionParams, id: number): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      commit('DISCOUNT_SAVING');
      try {
        await getDiscountApi(accessToken).discountsControllerRemove(id);
        commit('DISCOUNT_SAVED');
      } catch (error) {
        commit('DISCOUNT_SAVE_ERROR', error);
        logger.captureStoreError('removeDiscount', error, { id });
      }
    },
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    discounts: (state: IAdminDiscountsState): DiscountDto[] => state.discounts,
    discountProductTypes: (state: IAdminDiscountsState): DiscountProductTypeDto[] => state.discountProductTypes,
    discountProductTypesMap: (state: IAdminDiscountsState): Record<string, string> =>
      state.discountProductTypes.reduce((a, c) => ({ ...a, [c.discountProductTypeId]: c.description }), {}),
    discountsLoading: (state: IAdminDiscountsState): boolean => state.discountsLoading,
    discountsLoadError: (state: IAdminDiscountsState): string | null => state.discountsLoadError,
    discountProductTypesLoading: (state: IAdminDiscountsState): boolean => state.discountProductTypesLoading,
    discountProductTypesLoadError: (state: IAdminDiscountsState): string | null => state.discountProductTypesLoadError,
    discountSaving: (state: IAdminDiscountsState): boolean => state.discountSaving,
    discountSaveError: (state: IAdminDiscountsState): string | null => state.discountSaveError,
  },
};
