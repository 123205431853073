import OrderState, { IOrderState } from '@/store/modules/common/OrderState';
import { IActionParams } from '@/store/modules/index';

const order = new OrderState();

const initialState = (): IOrderState => ({
  ...order.state(),
});

type ActionParams = IActionParams<IOrderState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    ...order.mutations,

    RESET_STATE(state: IOrderState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    ...order.actions,

    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    ...order.getters,
  },
};
