import { AdminDemoDto, DemoUpdateDto } from '@/castapi';
import { AppLogger } from '@/logger';
import Vue from 'vue';
import { pick } from '@/shared/functions';
import { getDemosApi, getErrorMessage } from '@/castapi/helpers';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('adminDemos state');

interface IAdminDemosState {
  demos: AdminDemoDto[];
  demosLoading: boolean;
  demosLoadError: null | string;
  demo: null | AdminDemoDto;
  demoLoading: boolean;
  demoLoadError: null | string;
  demoSaving: boolean;
  demoSaveError: null | string;
}

const initialState = (): IAdminDemosState => ({
  demos: [],
  demosLoading: false,
  demosLoadError: null,
  demo: null,
  demoLoading: false,
  demoLoadError: null,
  demoSaving: false,
  demoSaveError: null,
});

type ActionParams = IActionParams<IAdminDemosState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    DEMOS_LOADING(state: IAdminDemosState): void {
      state.demosLoadError = null;
      state.demosLoading = true;
    },
    DEMOS_LOADED(state: IAdminDemosState, payload: AdminDemoDto[]): void {
      state.demos = payload;
      state.demosLoading = false;
    },
    DEMOS_LOAD_ERROR(state: IAdminDemosState, error: Error): void {
      state.demosLoadError = getErrorMessage(error);
      state.demosLoading = false;
    },
    DEMO_LOADING(state: IAdminDemosState): void {
      state.demoLoadError = null;
      state.demoLoading = true;
    },
    DEMO_LOADED(state: IAdminDemosState, payload: AdminDemoDto): void {
      state.demo = payload;
      state.demoLoading = false;
    },
    DEMO_LOAD_ERROR(state: IAdminDemosState, error: Error): void {
      state.demosLoadError = getErrorMessage(error);
      state.demoLoading = false;
    },
    DEMO_UPDATE_IN_PROGRESS(state: IAdminDemosState): void {
      state.demoSaving = true;
      state.demoSaveError = null;
    },
    DEMO_UPDATE_SUCCESS(state: IAdminDemosState, payload: AdminDemoDto): void {
      const ind = state.demos.findIndex(item => item.demoId === payload.demoId);
      Vue.set(state.demos, ind, payload);
      state.demoSaving = false;
    },
    DEMO_UPDATE_ERROR(state: IAdminDemosState, error: Error): void {
      state.demoSaving = false;
      state.demoSaveError = getErrorMessage(error);
    },
    RESET_STATE(state: IAdminDemosState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
    RESET_EDITOR_STATE(state: IAdminDemosState): void {
      state.demo = null;
      state.demoLoading = false;
      state.demoLoadError = null;
      state.demoSaving = false;
      state.demoSaveError = null;
    },
  },
  actions: {
    async loadDemos({ commit, rootGetters }: ActionParams): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('DEMOS_LOADING');
        const response = await getDemosApi(accessToken).demoControllerAdminFindAll();
        commit('DEMOS_LOADED', response.data);
      } catch (error) {
        commit('DEMOS_LOAD_ERROR', error);
        logger.captureStoreError('loadDemos', error);
      }
    },
    async loadDemo({ commit, rootGetters }: ActionParams, id: number): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('DEMO_LOADING');
        const response = await getDemosApi(accessToken).demoControllerAdminGet(id);
        commit('DEMO_LOADED', response.data);
      } catch (error) {
        commit('DEMO_LOAD_ERROR', error);
        logger.captureStoreError('loadDemos', error, { id });
      }
    },
    async updateDemo({ commit, rootGetters }: ActionParams, demo: AdminDemoDto): Promise<void> {
      try {
        commit('DEMO_UPDATE_IN_PROGRESS');
        const demoUpdateDto = pick(demo, ['downloadPath', 'description']) as DemoUpdateDto;
        await getDemosApi(rootGetters['login/accessToken']).demoControllerUpdate(demo.demoId, demoUpdateDto);
        commit('DEMO_UPDATE_SUCCESS', demo);
      } catch (error) {
        commit('DEMO_UPDATE_ERROR', error);
        logger.captureStoreError('updateDemo', error, { demo });
      }
    },
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
    resetEditorState({ commit }: ActionParams): void {
      commit('RESET_EDITOR_STATE');
    },
  },
  getters: {
    demos: (state: IAdminDemosState): AdminDemoDto[] => state.demos,
    demosLoading: (state: IAdminDemosState): boolean => state.demosLoading,
    demosLoadError: (state: IAdminDemosState): string | null => state.demosLoadError,
    demo: (state: IAdminDemosState): AdminDemoDto | null => state.demo,
    demoLoading: (state: IAdminDemosState): boolean => state.demoLoading,
    demoLoadError: (state: IAdminDemosState): string | null => state.demoLoadError,
    demoSaving: (state: IAdminDemosState): boolean => state.demoSaving,
    demoSaveError: (state: IAdminDemosState): string | null => state.demoSaveError,
  },
};
