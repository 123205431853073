import { HttpExceptionDto, InvoiceDto } from '@/castapi';
import download from 'downloadjs';
import router from '@/router';
import { AppLogger } from '@/logger';
import { getErrorMessage, getInvoicesApi } from '@/castapi/helpers';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('invoices state');

interface IInvoicesState {
  invoices: InvoiceDto[];
  invoicesLoadError: null | string;
  invoicesLoading: boolean;
}

const initialState = (): IInvoicesState => ({
  invoices: [],
  invoicesLoadError: null,
  invoicesLoading: false,
});

type ActionParams = IActionParams<IInvoicesState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RESET_STATE(state: IInvoicesState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
    INVOICES_LOADING(state: IInvoicesState): void {
      state.invoices = [];
      state.invoicesLoading = true;
      state.invoicesLoadError = null;
    },
    INVOICES_LOADED(state: IInvoicesState, invoices: InvoiceDto[]): void {
      state.invoicesLoading = false;
      state.invoices = [...invoices];
    },
    INVOICES_LOAD_ERROR(state: IInvoicesState, invoicesLoadError: Error): void {
      state.invoicesLoading = false;
      state.invoicesLoadError = getErrorMessage(invoicesLoadError);
    },
  },
  actions: {
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },

    async loadInvoices({ commit, rootGetters }: ActionParams): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('INVOICES_LOADING');
        const response = await getInvoicesApi(accessToken).invoicesControllerGetUserInvoices();
        commit('INVOICES_LOADED', response.data);
      } catch (error) {
        commit('INVOICES_LOAD_ERROR', error);
        logger.captureStoreError('loadInvoices', error);
      }
    },

    async downloadInvoice({ rootGetters }: ActionParams, invoiceId: number): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      try {
        const response = await getInvoicesApi(accessToken).invoicesControllerDownloadInvoicePdf(invoiceId);
        download(response.data, 'invoice.pdf', 'application/pdf');
      } catch (error) {
        console.error(error);
        logger.captureStoreError('downloadInvoice', error, { invoiceId });
      }
    },

    async downloadInvoiceWithToken({ rootGetters }: ActionParams, token: string): Promise<void> {
      try {
        const response = rootGetters && (await getInvoicesApi().invoicesControllerDownloadInvoiceWithToken(token));
        download(response.data, 'invoice.pdf', 'application/pdf');
      } catch (error) {
        if ((error as HttpExceptionDto).status === 404) {
          await router.push({ name: '404' });
        }
        console.error(error);
        logger.captureStoreError('downloadInvoiceWithToken', error);
      }
    },

    async downloadReportCsv({ rootGetters }: ActionParams, path: string): Promise<void> {
      try {
        const response = rootGetters && (await getInvoicesApi().invoicesControllerDownloadRerportCsv(path));
        download(response.data, path.split('/').pop(), 'text/csv');
      } catch (error) {
        if ((error as HttpExceptionDto).status === 404) {
          await router.push({ name: '404' });
        }
        logger.captureStoreError('downloadReportCsv', error);
        throw error;
      }
    },
  },
  getters: {
    invoicesList: (state: IInvoicesState): InvoiceDto[] => state.invoices,
    invoicesLoading: (state: IInvoicesState): boolean => state.invoicesLoading,
    invoicesLoadError: (state: IInvoicesState): string | null => state.invoicesLoadError,
  },
};
