import { BucketUploadFileDto } from '@/castapi';
import { AxiosPromise } from 'axios';

interface BucketApi {
  bucketControllerUploadFile(body: BucketUploadFileDto): AxiosPromise<string>;
}

export const uploadImage = async (
  avatarImage: string,
  pathWithoutExtension: string,
  bucketApi: BucketApi,
): Promise<string> => {
  const array = avatarImage.match(/^data:image\/(\w+);base64,/);
  const extension = array?.length ? `.${array[1]}` : '';
  const body = { file: avatarImage as unknown as object, path: `${pathWithoutExtension}${extension}` };
  const { data: url } = await bucketApi.bucketControllerUploadFile(body);
  const symbol = url.includes('?') ? '&' : '?';
  return `${url}${symbol}${new Date().getTime()}`; // Clarification: it's trick to avoid caching image by url
};
