import { UploadDto } from '@/castapi';
import Vue from 'vue';
import { AppLogger } from '@/logger';
import { getErrorMessage, getUploadsApi } from '@/castapi/helpers';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('adminUploads state');

interface IAdminUploadsState {
  uploads: UploadDto[];
  uploadsLoading: boolean;
  uploadsLoadError: null | string;
  uploadSaving: boolean;
  uploadSaveError: null | string;
}

const initialState = (): IAdminUploadsState => ({
  uploads: [],
  uploadsLoading: false,
  uploadsLoadError: null,
  uploadSaving: false,
  uploadSaveError: null,
});

type ActionParams = IActionParams<IAdminUploadsState>;

// noinspection JSUnusedGlobalSymbols
export default {
  namespaced: true,
  state: initialState,
  mutations: {
    UPLOADS_LOADING(state: IAdminUploadsState): void {
      state.uploadsLoadError = null;
      state.uploadsLoading = true;
    },
    UPLOADS_LOADED(state: IAdminUploadsState, payload: UploadDto[]): void {
      state.uploads = payload;
      state.uploadsLoading = false;
    },
    UPLOADS_LOAD_ERROR(state: IAdminUploadsState, error: Error): void {
      state.uploadsLoadError = getErrorMessage(error);
      state.uploadsLoading = false;
    },
    UPLOAD_ADD_IN_PROGRESS(state: IAdminUploadsState): void {
      state.uploadSaving = true;
      state.uploadSaveError = null;
    },
    UPLOAD_ADD_SUCCESS(state: IAdminUploadsState, payload: UploadDto): void {
      state.uploads.push(payload);
      state.uploadSaving = false;
    },
    UPLOAD_ADD_ERROR(state: IAdminUploadsState, error: Error): void {
      state.uploadSaving = false;
      state.uploadSaveError = getErrorMessage(error);
    },
    UPLOAD_UPDATE_IN_PROGRESS(state: IAdminUploadsState): void {
      state.uploadSaving = true;
      state.uploadSaveError = null;
    },
    UPLOAD_UPDATE_SUCCESS(state: IAdminUploadsState, payload: UploadDto): void {
      const ind = state.uploads.findIndex(item => item.id === payload.id);
      Vue.set(state.uploads, ind, payload);
      state.uploadSaving = false;
    },
    UPLOAD_UPDATE_ERROR(state: IAdminUploadsState, error: Error): void {
      state.uploadSaving = false;
      state.uploadSaveError = getErrorMessage(error);
    },
    UPLOAD_DELETE_IN_PROGRESS(state: IAdminUploadsState): void {
      state.uploadSaving = true;
      state.uploadSaveError = null;
    },
    UPLOAD_DELETE_SUCCESS(state: IAdminUploadsState, id: number): void {
      const index = state.uploads.findIndex(item => item.id === id);
      state.uploads.splice(index, 1);
      state.uploadSaving = false;
    },
    UPLOAD_DELETE_ERROR(state: IAdminUploadsState, error: Error): void {
      state.uploadSaving = false;
      state.uploadSaveError = getErrorMessage(error);
    },
    RESET_STATE(state: IAdminUploadsState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
    RESET_EDITOR_STATE(state: IAdminUploadsState): void {
      state.uploadSaveError = null;
      state.uploadSaving = false;
    },
  },
  actions: {
    async loadUploads({ commit, rootGetters }: ActionParams): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('UPLOADS_LOADING');
        const response = await getUploadsApi(accessToken).uploadsControllerFindAll();
        commit('UPLOADS_LOADED', response.data);
      } catch (error) {
        commit('UPLOADS_LOAD_ERROR', error);
        logger.captureStoreError('loadUploads', error);
      }
    },
    async addUpload({ commit, rootGetters }: ActionParams, data: UploadDto): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      commit('UPLOAD_ADD_IN_PROGRESS');
      try {
        const response = await getUploadsApi(accessToken).uploadsControllerCreate(data);
        commit('UPLOAD_ADD_SUCCESS', response.data);
      } catch (error) {
        commit('UPLOAD_ADD_ERROR', error);
        logger.captureStoreError('addUpload', error, { data });
      }
    },
    async updateUpload({ commit, rootGetters }: ActionParams, data: UploadDto): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      commit('UPLOAD_UPDATE_IN_PROGRESS');
      try {
        await getUploadsApi(accessToken).uploadsControllerUpdate(data.id, data);
        commit('UPLOAD_UPDATE_SUCCESS', data);
      } catch (error) {
        commit('UPLOAD_UPDATE_ERROR', error);
        logger.captureStoreError('updateUpload', error, { data });
      }
    },
    async removeUpload({ commit, rootGetters }: ActionParams, id: number): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      commit('UPLOAD_DELETE_IN_PROGRESS');
      try {
        await getUploadsApi(accessToken).uploadsControllerRemove(id);
        commit('UPLOAD_DELETE_SUCCESS', id);
      } catch (error) {
        commit('UPLOAD_DELETE_ERROR', error);
        logger.captureStoreError('removeUpload', error, { id });
      }
    },
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
    resetEditorState({ commit }: ActionParams): void {
      commit('RESET_EDITOR_STATE');
    },
  },
  getters: {
    uploads: (state: IAdminUploadsState): UploadDto[] => state.uploads,
    uploadsLoading: (state: IAdminUploadsState): boolean => state.uploadsLoading,
    uploadsLoadError: (state: IAdminUploadsState): string | null => state.uploadsLoadError,
    uploadSaving: (state: IAdminUploadsState): boolean => state.uploadSaving,
    uploadSaveError: (state: IAdminUploadsState): string | null => state.uploadSaveError,
  },
};
