import { IActionParams } from '@/store/modules/index';

export interface ISnackbarState {
  show: boolean;
  message: string;
  color: string;
  topAndCentered: boolean;
  timeout: number;
}

const initialState = (): ISnackbarState => ({
  show: false,
  message: '',
  color: '',
  topAndCentered: false,
  timeout: 5,
});

type ActionParams = IActionParams<ISnackbarState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SHOW_MESSAGE(
      state: ISnackbarState,
      payload: { message: string; color: string; topAndCentered: boolean; timeout: number },
    ): void {
      state.show = true;
      state.message = payload.message;
      state.color = payload.color || 'warning';
      state.topAndCentered = payload.topAndCentered;
      state.timeout = payload.timeout || 3;
    },
    CLOSE(state: ISnackbarState): void {
      state.show = false;
    },
    RESET_STATE(state: ISnackbarState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    show: (state: ISnackbarState): boolean => state.show,
    message: (state: ISnackbarState): string => state.message,
    color: (state: ISnackbarState): string => state.color,
    topAndCentered: (state: ISnackbarState): boolean => state.topAndCentered,
    timeout: (state: ISnackbarState): number => state.timeout,
  },
};
