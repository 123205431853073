import { UserDto } from '@/castapi';
import { isTestEnv } from './config';
import { getUserFullName } from './name';

export const insertScript = (scriptTagId: string, scriptUrl: string, async = false): void => {
  if (document.getElementById(scriptTagId)) {
    return;
  }
  const scriptTag = document.createElement('script');
  if (async) {
    scriptTag.async = true;
  }
  scriptTag.src = scriptUrl;
  document.head.appendChild(scriptTag);
};

export const removeScript = (scriptTagId: string): void => {
  const scriptTag = document.getElementById(scriptTagId);
  if (scriptTag) {
    scriptTag.remove();
  }
};

export const downloadFileUsingUrl = (url: string): void => {
  const link = document.createElement('a');
  const linkId = `download_link_${Math.ceil(Math.random() * 100000)}`;
  link.id = linkId;
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  if (document?.getElementById(linkId)) {
    document.body.removeChild(link);
  }
};

const isClarityAvailable = (): boolean => {
  if (isTestEnv() || window.location.href.includes('localhost')) {
    return false;
  }
  if (!window.clarity) {
    console.error('Clarity is not defined on the window.');
    return false;
  }
  return true;
};

export const setClarityEvent = (eventName: string): void => {
  if (!isClarityAvailable()) {
    return;
  }
  try {
    window.clarity('event', eventName);
  } catch (error) {
    console.error('Error setting clarity event', error);
  }
};

export const setClarityUpgrade = (reason: string): void => {
  if (!isClarityAvailable()) {
    return;
  }
  try {
    window.clarity('upgrade', reason);
  } catch (error) {
    console.error('Error setting clarity upgrade', error);
  }
};

export const setClarityTag = (tag: string, value: string): void => {
  if (!isClarityAvailable()) {
    return;
  }
  try {
    window.clarity('set', tag, value);
  } catch (error) {
    console.error('Error setting clarity event', error);
  }
};

export const setClarityIndentity = (user: UserDto, pageId?: string): void => {
  if (!isClarityAvailable()) {
    return;
  }
  if (!user) {
    console.error('setClarityIndentity(): User is not defined');
    return;
  }
  try {
    window.clarity('consent');
    window.clarity('identify', user.userId?.toString(), user.lastLoginDate || undefined, pageId, getUserFullName(user));
    setClarityTag('email', user.email);
    console.log('Clarity identity set', 'User:', JSON.stringify(user), 'PageId:', pageId);
  } catch (error) {
    console.error('Error setting clarity attribute', error);
  }
};
