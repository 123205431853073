import {
  AddressDto,
  CreateAddressDto,
  OrganizationDto,
  OrganizationExtendedDto,
  UpdateAddressDto,
  UserDto,
  UserRoleBindingDto,
  UserRoleDto,
  UserUpdateDto,
} from '@/castapi';

import { PERMISSIONS, USER_GROUP_IDS, USER_ROLE_IDS } from '@/shared/constants';
import { uploadImage } from '@/store/modules/common/Files';
import { omit } from '@/shared/functions';
import { AppLogger } from '@/logger';
import {
  getAddressApi,
  getBucketApi,
  getErrorMessage,
  getOrganizationsApi,
  getUsersApi,
  omitUserSecretFields,
} from '@/castapi/helpers';
import { Dummy } from '@/shared/types/common';
import { IActionParams } from '@/store/modules/index';

export interface RoleWithBindingDto extends UserRoleDto, UserRoleBindingDto {}

const logger = new AppLogger('organizations state');

const filterUniqueUsers = (user: UserDto, index: number, self: UserDto[]) =>
  self.findIndex(value => value.userId === user.userId) >= index;

interface IOrganizationState {
  organizations: OrganizationDto[];
  canCreateOrganization: boolean | null;
  addresses: AddressDto[];
  userRolesBinding: UserRoleBindingDto[];
  users: UserDto[];
  addressChanging: boolean;
  changeAddressError: string | null;
  // addressOperation: string | null;
  organizationChanging: boolean;
  changeOrganizationError: string | null;
  invitationCreating: boolean;
  invitationCreateError: string | null;
  organizationTransferring: boolean;
  organizationTransferError: string | null;
  userDeleting: UserDto | null;
  userDeleteError: string | null;
  userUpdating: UserDto | null;
  userUpdateError: string | null;
  userRolesChanging: UserDto | null;
  userRolesChangeError: string | null;
}

const initialState = (): IOrganizationState => ({
  organizations: [],
  addresses: [],
  userRolesBinding: [],
  users: [],
  addressChanging: false,
  changeAddressError: null,
  organizationChanging: false,
  changeOrganizationError: null,
  invitationCreating: false,
  invitationCreateError: null,
  // addressOperation: null,
  organizationTransferring: false,
  organizationTransferError: null,
  userDeleting: null,
  userDeleteError: null,
  userUpdating: null,
  userUpdateError: null,
  userRolesChanging: null,
  userRolesChangeError: null,
  canCreateOrganization: null,
});

interface Getters {
  organization: OrganizationDto;
  addresses: AddressDto[];
  organizationId: number;
  organizationUserRoles: UserRoleBindingDto[];
  organizationUsers: UserDto[];
  userRolesGetter: (userId: number) => RoleWithBindingDto[];
  activeUserMainRole: (userId: number) => RoleWithBindingDto;
  activeUserRoles: (userId: number) => RoleWithBindingDto[];
  userRolesBindingGetter: (userId: number) => UserRoleBindingDto[];
}

type ActionParams = IActionParams<IOrganizationState, Getters>;

// noinspection JSUnusedGlobalSymbols
export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RESET_STATE(state: IOrganizationState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
    ORGANIZATIONS_LOADED(state: IOrganizationState, organizationsExtended: OrganizationExtendedDto[]): void {
      state.organizations = [];
      state.addresses = [];
      state.userRolesBinding = [];
      state.users = [];
      organizationsExtended.forEach(org => {
        state.organizations = [...state.organizations, org.organization];
        state.addresses = [...state.addresses, ...org.addresses];
        state.userRolesBinding = [...state.userRolesBinding, ...org.rolesBinding];
        state.users = [...state.users, ...org.users];
      });
    },
    CAN_CREATE_ORGANIZATION_LOADED(state: IOrganizationState, canCreateOrganization: boolean): void {
      state.canCreateOrganization = canCreateOrganization;
    },
    ORGANIZATION_ADDRESSES_LOADED(
      state: IOrganizationState,
      { organizationId, addresses }: { organizationId: number; addresses: AddressDto[] },
    ): void {
      state.addresses = [
        ...state.addresses.filter(address => address.organizationRef !== organizationId),
        ...addresses,
      ];
    },
    ADDRESS_CHANGING(state: IOrganizationState): void {
      state.changeAddressError = null;
      state.addressChanging = true;
    },
    CHANGE_ADDRESS_ERROR(state: IOrganizationState, payload: Error): void {
      state.changeAddressError = getErrorMessage(payload);
      state.addressChanging = false;
      // state.addressOperation = payload;
    },
    ADDRESS_CHANGED(state: IOrganizationState /*, payload: string*/): void {
      state.addressChanging = false;
      // state.addressOperation = payload;
    },
    ORGANIZATION_CHANGING(state: IOrganizationState): void {
      state.changeOrganizationError = null;
      state.organizationChanging = true;
    },
    CHANGE_ORGANIZATION_ERROR(state: IOrganizationState, payload: Error): void {
      state.changeOrganizationError = getErrorMessage(payload);
      state.organizationChanging = false;
    },
    ORGANIZATION_CHANGED(state: IOrganizationState, newOrganization: OrganizationDto): void {
      state.organizationChanging = false;
      const index = state.organizations.findIndex(org => org.organizationId === newOrganization.organizationId);
      if (index > -1) {
        state.organizations.splice(index, 1, newOrganization);
      }
    },
    INVITATION_CREATING(state: IOrganizationState): void {
      state.invitationCreating = true;
      state.invitationCreateError = null;
    },
    INVITATION_CREATED(state: IOrganizationState): void {
      state.invitationCreating = false;
      state.invitationCreateError = null;
    },
    INVITATION_CANCELED(state: IOrganizationState): void {
      state.invitationCreating = false;
      state.invitationCreateError = null;
    },
    INVITATION_ERROR(state: IOrganizationState, payload: Error): void {
      state.invitationCreating = false;
      state.invitationCreateError = getErrorMessage(payload);
    },
    ORGANIZATION_TRANSFERRING(state: IOrganizationState): void {
      state.organizationTransferring = true;
      state.organizationTransferError = null;
    },
    ORGANIZATION_TRANSFERRED(state: IOrganizationState): void {
      state.organizationTransferring = false;
      state.organizationTransferError = null;
    },
    ORGANIZATION_TRANSFER_ERROR(state: IOrganizationState, payload: Error): void {
      state.organizationTransferring = false;
      state.organizationTransferError = getErrorMessage(payload);
    },
    USER_DELETING(state: IOrganizationState, user: UserDto): void {
      state.userDeleteError = null;
      state.userDeleting = user;
    },
    USER_DELETED(state: IOrganizationState): void {
      state.userDeleting = null;
      state.userDeleteError = null;
    },
    USER_DELETE_ERROR(state: IOrganizationState, payload: Error): void {
      state.userDeleting = null;
      state.userDeleteError = getErrorMessage(payload);
    },
    USER_ROLES_CHANGING(state: IOrganizationState, user: UserDto): void {
      state.userRolesChanging = user;
      state.userRolesChangeError = null;
    },
    USER_ROLES_CHANGED(state: IOrganizationState): void {
      state.userRolesChanging = null;
      state.userRolesChangeError = null;
    },
    USER_ROLES_CHANGE_ERROR(state: IOrganizationState, payload: Error): void {
      state.userRolesChanging = null;
      state.userRolesChangeError = getErrorMessage(payload);
    },
    USER_UPDATING(state: IOrganizationState, user: UserDto): void {
      state.userUpdating = user;
      state.userUpdateError = null;
    },
    USER_UPDATED(state: IOrganizationState): void {
      state.userUpdating = null;
    },
    USER_UPDATE_ERROR(state: IOrganizationState, payload: Error): void {
      state.userUpdateError = getErrorMessage(payload);
      state.userUpdating = null;
    },
  },
  actions: {
    async getUserOrganizations({ dispatch, commit, rootGetters }: ActionParams, token: string): Promise<void> {
      try {
        const response = await getOrganizationsApi(
          token || rootGetters['login/accessToken'],
        ).organizationsControllerGetUserOrganizations();
        commit('ORGANIZATIONS_LOADED', response.data);
        if (!response.data.length) {
          await dispatch('getCanCreateOrganization', token);
        }
      } catch (error) {
        logger.captureStoreError('getUserOrganizations', error);
        // Plan: handle error
      }
    },
    async getCanCreateOrganization({ commit, rootGetters }: ActionParams, token: string): Promise<void> {
      try {
        const response = await getUsersApi(
          token || rootGetters['login/accessToken'],
        ).usersControllerGetCanCreateOrganization();
        commit('CAN_CREATE_ORGANIZATION_LOADED', response.data);
      } catch (error) {
        logger.captureStoreError('getCanCreateOrganization', error);
      }
    },
    async getOrganizationAddresses({ commit, rootGetters }: ActionParams, organizationId: number): Promise<void> {
      try {
        const response = await getAddressApi(
          rootGetters['login/accessToken'],
        ).addressControllerGetOrganizationAddresses(organizationId);
        commit('ORGANIZATION_ADDRESSES_LOADED', { organizationId, addresses: response.data });
      } catch (error) {
        logger.captureStoreError('getOrganizationAddresses', error, { organizationId });
        // Plan: handle error
      }
    },
    async addAddress({ commit, dispatch, rootGetters }: ActionParams, address: CreateAddressDto): Promise<void> {
      commit('ADDRESS_CHANGING');
      try {
        await getAddressApi(rootGetters['login/accessToken']).addressControllerCreateAddress(address);
        if (rootGetters['login/isAdmin']) {
          await dispatch('adminOrganizations/getOrganizationData', address.organizationRef, { root: true });
        } else {
          await dispatch('getOrganizationAddresses', address.organizationRef);
        }
        commit('ADDRESS_CHANGED', 'added');
      } catch (error) {
        commit('CHANGE_ADDRESS_ERROR', error);
        logger.captureStoreError('addAddress', error, { address });
      }
    },
    async updateAddress({ commit, dispatch, rootGetters }: ActionParams, address: UpdateAddressDto): Promise<void> {
      commit('ADDRESS_CHANGING', address);
      try {
        await getAddressApi(rootGetters['login/accessToken']).addressControllerUpdateAddress(address);
        if (rootGetters['login/isAdmin']) {
          await dispatch('adminOrganizations/getOrganizationData', address.organizationRef, { root: true });
        } else {
          await dispatch('getOrganizationAddresses', address.organizationRef);
        }
        commit('ADDRESS_CHANGED', 'updated');
      } catch (error) {
        commit('CHANGE_ADDRESS_ERROR', error);
        logger.captureStoreError('updateAddress', error, { address });
      }
    },
    async deleteAddress({ commit, dispatch, rootGetters }: ActionParams, address: AddressDto): Promise<void> {
      commit('ADDRESS_CHANGING');
      try {
        await getAddressApi(rootGetters['login/accessToken']).addressControllerDeleteAddress({
          addressId: address.addressId,
        });
        if (rootGetters['login/isAdmin']) {
          await dispatch('adminOrganizations/getOrganizationData', address.organizationRef, { root: true });
        } else {
          await dispatch('getOrganizationAddresses', address.organizationRef);
        }
        commit('ADDRESS_CHANGED', 'deleted');
      } catch (error) {
        commit('CHANGE_ADDRESS_ERROR', error);
        logger.captureStoreError('deleteAddress', error, { address });
      }
    },
    async updateOrganizationData(
      { commit, rootState }: ActionParams,
      { organization, avatarImage }: { organization: OrganizationDto; avatarImage?: string },
    ): Promise<void> {
      commit('ORGANIZATION_CHANGING');
      try {
        let avatarUrl: null | string = null;
        const accessToken = rootState.login.accessToken;
        if (avatarImage) {
          avatarUrl = await uploadImage(
            avatarImage,
            `organization-avatars/${organization.organizationId}`,
            getBucketApi(accessToken),
          );
        }

        let updateBody = { ...organization };
        if (avatarImage && avatarUrl) {
          updateBody = { ...updateBody, organizationAvatar: avatarUrl };
        }

        const response = await getOrganizationsApi(accessToken).organizationsControllerUpdateOrganization(updateBody);
        commit('ORGANIZATION_CHANGED', response.data);
      } catch (error) {
        commit('CHANGE_ORGANIZATION_ERROR', error);
        logger.captureStoreError('updateOrganizationData', error, { organization, avatarImage });
      }
    },
    async inviteMember(
      { commit, dispatch, rootState }: ActionParams,
      {
        user,
        organization,
        roles,
      }: {
        user: UserDto;
        organization: OrganizationDto;
        roles: string[];
      },
    ): Promise<void> {
      commit('INVITATION_CREATING');
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerInviteMember({
          user,
          organization,
          roles,
        });
        commit('INVITATION_CREATED');
        await dispatch('getUserOrganizations', rootState.login.accessToken);
      } catch (error) {
        commit('INVITATION_ERROR', error);
        logger.captureStoreError('inviteMember', error, {
          invitedUser: omitUserSecretFields(user),
          organization,
          roles,
        });
      }
    },
    async transferOrganization(
      { commit, dispatch, rootState }: ActionParams,
      { userId, organizationId, notes }: { userId: number; organizationId: number; notes: string },
    ): Promise<void> {
      commit('ORGANIZATION_TRANSFERRING');
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerTransferOrganization({
          userId,
          organizationId,
          notes,
        });
        commit('ORGANIZATION_TRANSFERRED');
        await dispatch('getUserOrganizations', rootState.login.accessToken);
        await dispatch('adminOrganizations/getOrganizationData', organizationId, { root: true });
      } catch (error) {
        commit('ORGANIZATION_TRANSFER_ERROR', error);
        logger.captureStoreError('transferOrganization', error, { userId, organizationId, notes });
      }
    },
    async resendInvitationToMember(
      { commit, rootState }: ActionParams,
      {
        user,
        organization,
      }: {
        user: UserDto;
        organization: OrganizationDto;
      },
    ): Promise<void> {
      commit('INVITATION_CREATING');
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerResendMemberInvitation({ user, organization });
        commit('INVITATION_CREATED');
      } catch (error) {
        commit('INVITATION_ERROR', error);
        logger.captureStoreError('resendInvitationToMember', error, {
          invitedUser: omitUserSecretFields(user),
          organization,
        });
      }
    },
    async cancelMemberInvitation(
      { commit, rootState }: ActionParams,
      {
        user,
        organization,
      }: {
        user: UserDto;
        organization: OrganizationDto;
      },
    ): Promise<void> {
      commit('INVITATION_CREATING');
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerCancelUserInvitation({
          userId: user.userId,
          organizationId: organization.organizationId,
        });
        commit('INVITATION_CANCELED');
      } catch (error) {
        commit('INVITATION_ERROR', error);
        logger.captureStoreError('cancelMemberInvitation', error, {
          invitedUser: omitUserSecretFields(user),
          organization,
        });
      }
    },
    async removeUserFromOrganization(
      { commit, rootState }: ActionParams,
      {
        user,
        organization,
      }: {
        user: UserDto;
        organization: OrganizationDto;
      },
    ): Promise<void> {
      commit('USER_DELETING', user);
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerRemoveUserFromOrganization({
          userId: user.userId,
          organizationId: organization.organizationId,
        });
        commit('USER_DELETED');
      } catch (error) {
        commit('USER_DELETE_ERROR', error);
        logger.captureStoreError('removeUserFromOrganization', error, {
          removingUser: omitUserSecretFields(user),
          organization,
        });
      }
    },
    async changeUserRoles(
      { commit, dispatch, rootState, getters }: ActionParams,
      {
        user,
        roles,
      }: {
        user: UserDto;
        roles: string[];
      },
    ): Promise<void> {
      commit('USER_ROLES_CHANGING', user);
      const organizationId = getters.organization.organizationId;
      try {
        await getUsersApi(rootState.login.accessToken).usersControllerChangeUserRoles({
          organizationId,
          roles,
          userId: user.userId,
        });
        commit('USER_ROLES_CHANGED');
        await dispatch('getUserOrganizations', rootState.login.accessToken);
      } catch (error) {
        commit('USER_ROLES_CHANGE_ERROR', error);
        logger.captureStoreError('changeUserRoles', error, {
          changingUser: omitUserSecretFields(user),
          roles,
        });
      }
    },
    async updateUser(
      { commit, dispatch, getters, rootGetters }: ActionParams,
      { user, avatarImage }: { user: UserDto; avatarImage?: string },
    ): Promise<void> {
      commit('USER_UPDATING', user);
      try {
        let avatar: null | string = null;
        const accessToken = rootGetters['login/accessToken'];
        if (avatarImage) {
          avatar = await uploadImage(avatarImage, `user-avatars/${user.userId}`, getBucketApi(accessToken));
        }

        let updateBody = omit(
          user,
          Object.keys(user).filter(f => !['firstName', 'lastName', 'phoneNumber', 'email', 'avatar'].includes(f)),
        ) as UserUpdateDto;
        if (avatarImage && avatar) {
          updateBody = { ...updateBody, avatar };
        }

        const response = await getUsersApi(accessToken).usersControllerUpdate(
          user.userId,
          getters.organizationId,
          updateBody,
        );
        if (rootGetters['login/user'].userId === user.userId) {
          await dispatch('login/updateUser', response.data, { root: true });
        } else {
          await dispatch('getUserOrganizations', accessToken);
        }
        commit('USER_UPDATED');
      } catch (error) {
        commit('USER_UPDATE_ERROR', error);
        logger.captureStoreError('updateUser', error, { changingUser: omitUserSecretFields(user), avatarImage });
      }
    },
    async createOrganization(
      { commit, dispatch, rootGetters }: ActionParams,
      { organizationName, countryRef }: { organizationName: string; countryRef: number },
    ): Promise<void> {
      commit('ORGANIZATION_CHANGING');
      try {
        const accessToken = rootGetters['login/accessToken'];
        const userId = rootGetters['login/userId'];
        await getOrganizationsApi(accessToken).organizationsControllerCreateOrganization({
          userId,
          organizationName,
          countryRef,
        });
        commit('ORGANIZATION_CHANGED');
        await dispatch('login/GET_CURRENT_USER_DATA', accessToken, { root: true });
      } catch (error) {
        commit('CHANGE_ORGANIZATION_ERROR', error);
        logger.captureStoreError('createOrganization', error, { organizationName, countryRef });
      }
    },
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    addresses: (state: IOrganizationState): AddressDto[] => state.addresses,
    // addressOperation: (state: IOrganizationState) => state.addressOperation,
    shippingAddresses: (state: IOrganizationState): AddressDto[] =>
      state.addresses.filter(address => !address.isBilling),
    billingAddresses: (state: IOrganizationState): AddressDto[] => state.addresses.filter(address => address.isBilling),
    addressChanging: (state: IOrganizationState): boolean => state.addressChanging,
    changeAddressError: (state: IOrganizationState): string | null => state.changeAddressError,
    organization: (state: IOrganizationState): OrganizationDto | null =>
      state.organizations.length ? state.organizations[0] : null,
    organizationId: (_state: IOrganizationState, getters: Getters): number => getters.organization?.organizationId,
    organizationName: (_state: IOrganizationState, getters: Getters): string => getters.organization?.organizationName,
    organizationAddresses: (_state: IOrganizationState, getters: Getters): AddressDto[] =>
      getters.organization
        ? getters.addresses.filter((address: AddressDto) => address.organizationRef === getters.organizationId)
        : [],
    organizationChanging: (state: IOrganizationState): boolean => state.organizationChanging,
    changeOrganizationError: (state: IOrganizationState): string | null => state.changeOrganizationError,
    organizationUserRoles: (state: IOrganizationState, getters: Getters): UserRoleBindingDto[] =>
      state.userRolesBinding.filter(roleBinding => roleBinding.organizationRef === getters.organizationId),
    organizationUsers: (state: IOrganizationState, getters: Getters): UserDto[] =>
      state.users
        .filter(user => getters.organizationUserRoles.find((role: UserRoleBindingDto) => role.userRef === user.userId))
        .filter(filterUniqueUsers),
    organizationActiveUsers: (_state: IOrganizationState, getters: Getters): UserDto[] =>
      getters.organizationUsers.filter((user: UserDto) => user.active),
    userRolesBindingGetter:
      (_state: IOrganizationState, getters: Getters) =>
      (userId: number): UserRoleBindingDto[] =>
        getters.organizationUserRoles.filter((roleBinding: UserRoleBindingDto) => roleBinding.userRef === userId),
    userRolesBinding: (state: IOrganizationState): UserRoleBindingDto[] => state.userRolesBinding,
    invitationCreating: (state: IOrganizationState): boolean => state.invitationCreating,
    invitationCreateError: (state: IOrganizationState): string | null => state.invitationCreateError,
    organizationTransferring: (state: IOrganizationState): boolean => state.organizationTransferring,
    organizationTransferError: (state: IOrganizationState): string | null => state.organizationTransferError,
    userDeleteError: (state: IOrganizationState): string | null => state.userDeleteError,
    userRolesGetter:
      (
        _state: IOrganizationState,
        getters: Getters,
        _rootState: Dummy,
        rootGetters: {
          'dictionary/roleTypeGetter': (roleId: number) => UserRoleDto;
        },
      ) =>
      (userId: number): RoleWithBindingDto[] =>
        getters.userRolesBindingGetter(userId).map((binding: UserRoleBindingDto) => ({
          ...rootGetters['dictionary/roleTypeGetter'](binding.userRoleRef),
          ...binding,
        })),
    userMainRole:
      (_state: IOrganizationState, getters: Getters) =>
      (userId: number): RoleWithBindingDto | null =>
        getters
          .userRolesGetter(userId)
          .reduce(
            (a: RoleWithBindingDto | null, c: RoleWithBindingDto) => (a && a.priority > c.priority ? a : c),
            null,
          ),
    activeUserRoles:
      (_state: IOrganizationState, getters: Getters) =>
      (userId: number): RoleWithBindingDto[] =>
        getters.userRolesGetter(userId).filter((r: RoleWithBindingDto) => r.isActive),
    activeUserMainRole:
      (_state: IOrganizationState, getters: Getters) =>
      (userId: number): RoleWithBindingDto | null =>
        getters
          .userRolesGetter(userId)
          .filter(r => r.isActive)
          .reduce(
            (a: RoleWithBindingDto | null, c: RoleWithBindingDto) => (a && a.priority > c.priority ? a : c),
            null,
          ),
    activeUserMainRoleWithPermissions:
      (_state: IOrganizationState, getters: Getters): ((userId: number) => RoleWithBindingDto) | null =>
      (userId: number) => ({
        ...getters.activeUserMainRole(userId),
        ...getters.userRolesGetter(userId).reduce((result: Record<string, boolean>, role: RoleWithBindingDto) => {
          return Object.values(PERMISSIONS).reduce((obj, key) => ({ ...obj, [key]: result[key] || role[key] }), {});
        }, {}),
      }),
    isAdmin:
      (_state: IOrganizationState, getters: Getters) =>
      (userId: number): boolean =>
        getters.activeUserMainRole(userId)?.userGroupRef === USER_GROUP_IDS.ADMIN,
    isOwner:
      (_state: IOrganizationState, getters: Getters) =>
      (userId: number): boolean =>
        getters.activeUserMainRole(userId)?.userRoleId === USER_ROLE_IDS.OWNER_USER_ROLE_ID,
    isDemo:
      (_state: IOrganizationState, getters: Getters) =>
      (userId: number): boolean =>
        getters.activeUserMainRole(userId)?.userRoleId === USER_ROLE_IDS.DEMO_USER_ROLE_ID,
    isMember:
      (_state: IOrganizationState, getters: Getters) =>
      (userId: number): boolean =>
        getters.activeUserMainRole(userId)?.userRoleId === USER_ROLE_IDS.MEMBER_USER_ROLE_ID,
    isEmptyRole:
      (_state: IOrganizationState, getters: Getters) =>
      (userId: number): boolean =>
        !getters.activeUserRoles(userId).length,
    roleId:
      (_state: IOrganizationState, getters: Getters) =>
      (userId: number): number | null =>
        getters.activeUserMainRole(userId)?.userRoleId,
    roleName:
      (_state: IOrganizationState, getters: Getters) =>
      (userId: number): string | null =>
        getters.activeUserMainRole(userId)?.userRoleName?.toLowerCase(),
    userRolesChanging: (state: IOrganizationState): UserDto | null => state.userRolesChanging,
    userRolesChangeError: (state: IOrganizationState): string | null => state.userRolesChangeError,
    userUpdating: (state: IOrganizationState): UserDto | null => state.userUpdating,
    userUpdateError: (state: IOrganizationState): string | null => state.userUpdateError,
    canCreateOrganization: (state: IOrganizationState): boolean | null => state.canCreateOrganization,
  },
};
