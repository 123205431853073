import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import { ADMIN_PAGE_TITLE, DEFAULT_PAGE_TITLE, STORE_PAGE_TITLE } from '@/shared/constants';
import snackbarPlugin from '@/plugins/snackbar';
import { isCloudOn, isTestEnv } from '@/shared/functions';
import { Route } from 'vue-router/types/router';

Vue.use(VueRouter);

export type MoaRouterConfig = RouteConfig & { meta: { roles: string[] } };

export const routes: Array<MoaRouterConfig> = [
  {
    // Just for detect '/' path and then redirect to the home page depending on the user role
    path: '/',
    name: 'Home',
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/dongles',
    name: 'Dongles',
    component: () =>
      import(
        /* webpackChunkName: "Dongles" */
        '../components/admin/Dongles/Dongles.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  //Cloud Products
  {
    path: '/cloud-products',
    name: 'Cloud',
    component: () =>
      import(
        /* webpackChunkName: "Dongles" */
        '../components/admin/CloudProducts/CloudProducts.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
    hidden: !isCloudOn(),
  },
  // Users
  {
    path: '/pending-users',
    name: 'pendingUsers',
    component: () =>
      import(
        /* webpackChunkName: "Users" */
        '../components/admin/Users/PendingUsers.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () =>
      import(
        /* webpackChunkName: "Users" */
        '../components/admin/Users/Users.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/users/:id',
    name: 'edit-user',
    component: () =>
      import(
        /* webpackChunkName: "Users" */
        '../components/admin/Users/EditUser.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Organizations
  {
    path: '/organizations',
    name: 'organizations',
    component: () =>
      import(
        /* webpackChunkName: "Organizations" */
        '../components/admin/Organizations/Organizations.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/organizations/new',
    name: 'create-account',
    component: () =>
      import(
        /* webpackChunkName: "Organizations" */
        '../components/admin/Organizations/CreateAccount.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/organizations/:id',
    name: 'edit-organization',
    component: () =>
      import(
        /* webpackChunkName: "Organizations" */
        '../components/admin/Organizations/EditOrganization.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/organizations/:id/invoice-history',
    name: 'organization-invoice-history',
    component: () =>
      import(
        /* webpackChunkName: "Organizations" */
        '../components/admin/Organizations/OrganizationInvoiceHistory.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Uploads
  {
    path: '/uploads',
    name: 'uploads',
    component: () =>
      import(
        /* webpackChunkName: "Uploads" */
        '../components/admin/Uploads/Uploads.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/uploads/new',
    name: 'add-upload-link',
    component: () =>
      import(
        /* webpackChunkName: "Uploads" */
        '../components/admin/Uploads/EditUpload.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/uploads/:id',
    name: 'edit-upload-link',
    component: () =>
      import(
        /* webpackChunkName: "Uploads" */
        '../components/admin/Uploads/EditUpload.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Demos
  {
    path: '/admin-demos',
    name: 'admin-demos',
    component: () =>
      import(
        /* webpackChunkName: "AdminDemos" */
        '../components/admin/Demos/DemosList.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/admin-demos/:id',
    name: 'admin-demos-edit',
    component: () =>
      import(
        /* webpackChunkName: "AdminDemos" */
        '../components/admin/Demos/EditDemo.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Discounts
  {
    path: '/discounts',
    name: 'discounts',
    component: () =>
      import(
        /* webpackChunkName: "Discounts" */
        '../components/admin/Discounts/Discounts.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/discounts/new',
    name: 'add-discount',
    component: () =>
      import(
        /* webpackChunkName: "Discounts" */
        '../components/admin/Discounts/EditDiscount.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/discounts/:id',
    name: 'edit-discount',
    component: () =>
      import(
        /* webpackChunkName: "Discounts" */
        '../components/admin/Discounts/EditDiscount.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Staff
  {
    path: '/staff',
    name: 'Staff',
    component: () =>
      import(
        /* webpackChunkName: "Staff" */
        '../components/customer/People/People.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/staff/:id',
    name: 'staff-user-profile',
    component: () =>
      import(
        /* webpackChunkName: "Staff" */
        '../components/customer/People/UserProfile.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Orders
  {
    path: '/orders',
    name: 'orders',
    component: () =>
      import(
        /* webpackChunkName: "Orders" */
        '../components/admin/Orders/Orders.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/orders/new',
    name: 'add-order',
    component: () =>
      import(
        /* webpackChunkName: "Orders" */
        '../components/admin/Orders/EditOrder.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/orders/:orderId',
    name: 'edit-order',
    component: () =>
      import(
        /* webpackChunkName: "Orders" */
        '../components/admin/Orders/EditOrder.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () =>
      import(
        /* webpackChunkName: "Inventory" */
        '../components/admin/Inventory/Inventory.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Shop
  {
    path: '/shop',
    name: 'shop',
    component: () =>
      import(
        /* webpackChunkName: "Shop" */
        '../components/shop/Front/Front.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'unauthorized'],
      title: STORE_PAGE_TITLE,
    },
  },
  {
    path: '/shop/cart',
    name: 'shop-cart',
    component: () =>
      import(
        /* webpackChunkName: "Shop" */
        '../components/shop/Cart/Cart.vue'
      ),
    meta: {
      roles: ['owner', 'demo'],
      title: STORE_PAGE_TITLE,
    },
  },
  {
    path: '/shop/checkout',
    name: 'shop-checkout',
    component: () =>
      import(
        /* webpackChunkName: "Shop" */
        '../components/shop/Checkout/Checkout.vue'
      ),
    meta: {
      roles: ['owner', 'demo'],
      title: STORE_PAGE_TITLE,
    },
  },
  {
    path: '/shop/receipt/:orderId',
    name: 'shop-receipt',
    component: () =>
      import(
        /* webpackChunkName: "Receipt" */
        '../components/shop/Receipt/Receipt.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'demo'],
      title: STORE_PAGE_TITLE,
    },
  },
  // Login
  {
    path: '/auth/forgot-password',
    name: 'loginforgotpassword',
    component: () =>
      import(
        /* webpackChunkName: "Auth" */
        '../components/auth/ResetPassword/ResetPassword.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/registration/:token',
    name: 'loginregistrationwithtoken',
    component: () =>
      import(
        /* webpackChunkName: "Auth" */
        '../components/auth/Registration/Registration.vue'
      ),
    meta: {
      roles: ['unauthorized'],
    },
  },
  {
    path: '/registration',
    name: 'loginregistration',
    component: () =>
      import(
        /* webpackChunkName: "Auth" */
        '../components/auth/Registration/Registration.vue'
      ),
    meta: {
      roles: ['unauthorized'],
    },
  },
  {
    path: '/auth/set-new-password/:token',
    name: 'loginsetnewpassword',
    component: () =>
      import(
        /* webpackChunkName: "Auth" */
        '@/components/auth/ResetPassword/SetNewPassword.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/auth/change-password',
    name: 'changepassword',
    component: () =>
      import(
        /* webpackChunkName: "Auth" */
        '../components/auth/ChangePassword/ChangePassword.vue'
      ),
    meta: {
      roles: ['demo', 'owner', 'member', 'admin'],
    },
  },
  {
    path: '/signin',
    name: 'loginsignin',
    component: () =>
      import(
        /* webpackChunkName: "Auth" */
        '../components/auth/Signin/Signin.vue'
      ),
    meta: {
      roles: ['unauthorized'],
    },
  },
  {
    path: '/auth/signout',
    name: 'loginsignout',
    component: () =>
      import(
        /* webpackChunkName: "Auth" */
        '../components/auth/Signout/Signout.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'member', 'admin', 'emptyRole'],
    },
  },
  {
    path: '/reauth',
    name: 'reauth',
    component: () =>
      import(
        /* webpackChunkName: "Auth" */
        '../components/auth/Reauthorisation/Reauthorisation.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'member'],
    },
  },
  {
    path: '/reauth-or-login',
    name: 'reauthOrLogin',
    component: () =>
      import(
        /* webpackChunkName: "Auth" */
        '../components/auth/Reauthorisation/ReauthOrLogin.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'member', 'unauthorized'],
    },
  },
  // Downloads
  {
    path: '/downloads',
    name: 'downloads',
    component: () =>
      import(
        /* webpackChunkName: "Downloads" */
        '../components/customer/Downloads/Downloads.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'member'],
    },
  },
  {
    path: '/downloads/:id',
    name: 'product-downloads',
    component: () =>
      import(
        /* webpackChunkName: "Downloads" */
        '../components/customer/Downloads/ProductDownloads.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'member'],
    },
  },
  {
    path: '/downloads/:id/info',
    name: 'product-info',
    component: () =>
      import(
        /* webpackChunkName: "Downloads" */
        '../components/customer/Downloads/DownloadInfo.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'member'],
    },
  },
  // People
  {
    path: '/people',
    name: 'people',
    component: () =>
      import(
        /* webpackChunkName: "People" */
        '../components/customer/People/People.vue'
      ),
    meta: {
      roles: ['owner'],
    },
  },
  {
    path: '/people/:id',
    name: 'user-profile',
    component: () =>
      import(
        /* webpackChunkName: "People" */
        '../components/customer/People/UserProfile.vue'
      ),
    meta: {
      roles: ['demo', 'owner', 'member', 'admin'],
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () =>
      import(
        /* webpackChunkName: "Invoices" */
        '../components/customer/Invoices/Invoices.vue'
      ),
    meta: {
      roles: ['owner'],
    },
  },
  {
    path: '/download-invoice/:token',
    name: 'invoicedownload',
    component: () =>
      import(
        /* webpackChunkName: "Invoices" */
        '../components/shop/Invoice/InvoiceDownload.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/download-report-csv/:path',
    name: 'report-csv-download',
    component: () =>
      import(
        /* webpackChunkName: "Invoices" */
        '../components/shop/Invoice/ReportCsvDownload.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  // Products
  {
    path: '/products',
    name: 'products',
    component: () =>
      import(
        /* webpackChunkName: "Products" */
        '../components/customer/Products/Products.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'demo'],
    },
  },
  // Demo
  {
    path: '/demo/:demoLinkName',
    name: 'download-demo',
    component: () =>
      import(
        /* webpackChunkName: "DemoDownload" */
        '../components/customer/Demos/DemoDownload.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'demo'],
    },
  },
  {
    path: '/demo/:demoLinkName/result',
    name: 'download-demo-result',
    component: () =>
      import(
        /* webpackChunkName: "DemoDownload" */
        '../components/customer/Demos/DemoDownloadResult.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'demo'],
    },
  },
  {
    path: '/organization',
    name: 'organization',
    component: () =>
      import(
        /* webpackChunkName: "Organization" */
        '../components/customer/Organization/Organization.vue'
      ),
    meta: {
      roles: ['owner', 'emptyRole'],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () =>
      import(
        /* webpackChunkName: "Profile" */
        '../components/customer/People/Profile.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'admin', 'demo'],
    },
  },
  {
    path: '/access-denied',
    name: '403',
    component: () =>
      import(
        /* webpackChunkName: "403" */
        '../components/common/403.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () =>
      import(
        /* webpackChunkName: "feedback" */
        '../components/customer/Feedbacks/FeedbackForm.vue'
      ),
    meta: {
      roles: ['demo', 'owner', 'member', 'admin'],
    },
  },
  {
    path: '/feedbacks',
    name: 'all-feedback',
    component: () =>
      import(
        /* webpackChunkName: "feedbacks" */
        '../components/admin/Feedbacks/Feedbacks.vue'
      ),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/feedbacks/:id',
    name: 'view-feedback',
    component: () =>
      import(
        /* webpackChunkName: "feedbacks" */
        '../components/admin/Feedbacks/ViewFeedback.vue'
      ),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/support-request',
    name: 'supportRequest',
    component: () =>
      import(
        /* webpackChunkName: "supportRequest" */
        '../components/customer/TechSupport/TechSupportFormStub.vue'
      ),
    meta: {
      roles: ['owner', 'member'],
    },
  },
  {
    path: '/support-request-test-form',
    name: 'supportRequestTest',
    component: () =>
      import(
        /* webpackChunkName: "supportRequest" */
        '../components/customer/TechSupport/TechSupportForm.vue'
      ),
    meta: {
      roles: ['owner', 'member'],
    },
  },
  {
    path: '/dongle-web-simulator',
    name: 'dongle-web-simulator',
    component: () =>
      import(
        /* webpackChunkName: "Utils" */
        '../components/common/DongleWebSimulator.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/page-not-found',
    name: '404',
    component: () =>
      import(
        /* webpackChunkName: "404" */
        '../components/common/404.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '*',
    redirect: { name: '404' },
    meta: {
      roles: ['everyone'],
    },
  },
].filter(route => !route.hidden);

const router = new VueRouter({ routes, mode: 'history' });

export const getHomePath = function (): string {
  if (store.getters['login/loggedIn']) {
    if (store.getters['login/isAdmin']) {
      return '/dongles';
    }
    if (store.getters['login/isMember']) {
      return '/products';
    } else if (store.getters['login/isEmptyRole']) {
      return '/organization';
    }
    return '/products';
  }
  return '/shop';
};

function roleIsCorrect(to: Route) {
  return (
    (store.getters['login/isAdmin'] && to.meta?.roles.includes('admin')) ||
    (store.getters['login/isOwner'] && to.meta?.roles.includes('owner')) ||
    (store.getters['login/isDemo'] && to.meta?.roles.includes('demo')) ||
    (store.getters['login/isMember'] && to.meta?.roles.includes('member')) ||
    (store.getters['login/isEmptyRole'] && to.meta?.roles.includes('emptyRole'))
  );
}

// Note we ignoring to.query to have possibility to set { query: { search: 'value' } } in the router.push() and avoid
// page refresh, this can be changed by using <router-view :key="$route.fullPath" /> in the App.vue
router.beforeEach(async (to, from, next): Promise<void> => {
  // If particular path is not specified, redirect to the home page, depending on the user role
  if (to.fullPath === '/') {
    next({ path: getHomePath() });
  }
  // Dongle web simulator is only available in test environment
  if (to.name === 'dongle-web-simulator' && !isTestEnv()) {
    next({ path: '/page-not-found' });
  }
  // Keep previous route for the back button
  await store.dispatch('login/setPrevRoute', {
    name: from.name,
    path: from.path,
    fullPath: from.fullPath,
    query: from.query,
  });

  // EVERYONE
  if (JSON.stringify(to.meta?.roles) === JSON.stringify(['everyone'])) {
    next();
    // UNAUTHORIZED ONLY
  } else if (JSON.stringify(to.meta?.roles) === JSON.stringify(['unauthorized'])) {
    if (store.getters['login/loggedIn']) {
      if (to.name === 'loginregistrationwithtoken') {
        snackbarPlugin.error('Log out first to use the link.', 60);
      }
      next({ path: getHomePath() });
    } else {
      if (
        !to.query.redirect &&
        !['/'].includes(from.fullPath) &&
        !['shop', 'loginsetnewpassword'].includes(from.name as string)
      ) {
        to.query.redirect = from.fullPath;
      }
      next();
    }
    // AUTHORIZED: OWNER, MEMBER, DEMO, ADMIN
  } else if (
    ['owner', 'member', 'demo', 'admin', 'emptyRole', 'unauthorized'].find(role => to.meta?.roles.includes(role))
  ) {
    if (store.getters['login/loggedIn']) {
      if (roleIsCorrect(to)) {
        next();
      } else {
        if (to.name === 'shop-cart' && store.getters['login/isMember']) {
          snackbarPlugin.error(
            'You are logged in as a company member. You can only buy the product if you are the owner of the ' +
              'company. If you want to purchase a product, please log out and create an account as the owner of the ' +
              'company.',
            3600,
          );
        }
        next({ path: getHomePath() });
      }
    } else {
      // UNAUTHORIZED ALLOWED
      if (to.meta?.roles.includes('unauthorized')) {
        next();
      } else {
        next({ path: '/signin', query: { redirect: to.fullPath } });
      }
    }
    // UNKNOWN ROLE
  } else {
    next({ path: '/access-denied' }); // make sure to always call next()!
    console.error('Error in role based access control');
  }
});

router.afterEach(to => {
  Vue.nextTick((): void => {
    if (to.fullPath === getHomePath()) {
      if (store.getters['login/isAdmin']) {
        document.title = ADMIN_PAGE_TITLE;
      } else if (store.getters['login/isOwner'] || store.getters['login/isDemo']) {
        document.title = STORE_PAGE_TITLE;
      } else {
        document.title = DEFAULT_PAGE_TITLE;
      }
    } else {
      document.title = to.meta?.title || DEFAULT_PAGE_TITLE;
    }
  });
});

export default router;
