import { UsersItemDto } from '@/castapi';
import { AppLogger } from '@/logger';
import { getErrorMessage, getUsersApi, ISearchParams } from '@/castapi/helpers';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('adminPendingUsers state');

interface IAdminPendingUsers {
  users: UsersItemDto[];
  usersLoading: boolean;
  usersLoadError: null | string;
  userChanging: null | number;
  deleteUserError: null | string;
  updateUserError: null | string;
  invitationSending: boolean;
  invitationError: null | string;
}

const initialState = (): IAdminPendingUsers => ({
  users: [],
  usersLoading: false,
  usersLoadError: null,
  userChanging: null,
  deleteUserError: null,
  updateUserError: null,
  invitationSending: false,
  invitationError: null,
});

type ActionParams = IActionParams<IAdminPendingUsers>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    USERS_LOADING(state: IAdminPendingUsers): void {
      state.usersLoading = true;
      state.usersLoadError = null;
    },
    USERS_LOADED(state: IAdminPendingUsers, users: UsersItemDto[]): void {
      state.users = users;
      state.usersLoading = false;
    },
    USERS_LOAD_ERROR(state: IAdminPendingUsers, payload: Error): void {
      state.usersLoading = false;
      state.usersLoadError = getErrorMessage(payload);
    },
    USER_DELETED(state: IAdminPendingUsers): void {
      state.userChanging = null;
    },
    DELETE_USER_ERROR(state: IAdminPendingUsers, payload: Error): void {
      state.deleteUserError = getErrorMessage(payload);
      state.userChanging = null;
    },
    INVITATION_SENDING(state: IAdminPendingUsers): void {
      state.invitationSending = true;
      state.invitationError = null;
    },
    INVITATION_ERROR(state: IAdminPendingUsers, error: Error): void {
      state.invitationSending = false;
      state.invitationError = getErrorMessage(error);
    },
    INVITATION_SENT(state: IAdminPendingUsers): void {
      state.invitationSending = false;
    },
    RESET_STATE(state: IAdminPendingUsers): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    async loadUsers({ commit, rootGetters }: ActionParams, searchParams: ISearchParams): Promise<void> {
      const { limit, offset, searchText, sortBy, sortDesc } = searchParams || {};
      commit('USERS_LOADING');
      try {
        const response = await getUsersApi(rootGetters['login/accessToken']).usersControllerGetAllPendingUsers(
          limit,
          offset,
          searchText,
          sortBy,
          sortDesc,
        );
        commit('USERS_LOADED', response.data);
      } catch (error) {
        commit('USERS_LOAD_ERROR', error);
        logger.captureStoreError('loadUsers', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },
    async sendInvitation({ commit, rootGetters }: ActionParams, userId: number): Promise<void> {
      commit('INVITATION_SENDING');
      try {
        await getUsersApi(rootGetters['login/accessToken']).usersControllerSendAccountActivationEmail(userId);
        commit('INVITATION_SENT');
      } catch (error) {
        commit('INVITATION_ERROR', error);
      }
    },
    async sendMultipleInvitations({ commit, rootGetters }: ActionParams, userIds: number[]): Promise<void> {
      commit('INVITATION_SENDING');
      try {
        await getUsersApi(rootGetters['login/accessToken']).usersControllerSendAccountActivationEmails({ userIds });
        commit('INVITATION_SENT');
      } catch (error) {
        commit('INVITATION_ERROR', error);
      }
    },
    async removeUser({ commit, rootGetters }: ActionParams, userId: number): Promise<void> {
      commit('USER_CHANGING', userId);
      try {
        await getUsersApi(rootGetters['login/accessToken']).usersControllerDeleteUser({ userId });
        commit('USER_DELETED');
      } catch (error) {
        commit('DELETE_USER_ERROR', error);
        logger.captureStoreError('removeUser', error, { userId });
      }
    },
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    users: (state: IAdminPendingUsers): UsersItemDto[] => state.users,
    invitationError: (state: IAdminPendingUsers): string | null => state.invitationError,
    // invitationSending: (state:IAdminPendingUsers) => state.invitationSending,
    usersLoading: (state: IAdminPendingUsers): boolean => state.usersLoading,
    usersLoadError: (state: IAdminPendingUsers): string | null => state.usersLoadError,
  },
};
