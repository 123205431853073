import { ActionContext } from 'vuex';

export { default as adminDemos } from './adminDemos';
export { default as adminDiscounts } from './adminDiscounts';
export { default as adminDongles } from './adminDongles';
export { default as adminInventory } from './adminInventory';
export { default as adminOrganizations } from './adminOrganizations';
export { default as adminOrders } from './adminOrders';
export { default as adminUploads } from './adminUploads';
export { default as adminReleases } from './adminReleases';
export { default as adminUsers } from './adminUsers';
export { default as login } from './login';
export { default as shop } from './shop';
export { default as snackbar } from './snackbar';
export { default as dictionary } from './dictionary';
export { default as invoices } from './invoices';
export { default as organizations } from './organizations';
export { default as customerDownloads } from './customerDownloads';
export { default as demo } from './demo';
export { default as dongles } from './dongles';
export { default as feedback } from './feedback';
export { default as adminCloudProducts } from './adminCloudProducts';
export { default as cloudProducts } from './cloudProducts';
export { default as support } from './support';
export { default as adminPendingUsers } from './adminPendingUsers';

export type RootGetters = { 'login/accessToken': string };

// interface AppRootState {
//   login: {
//     accessToken: string;
//   };
// }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IActionParams<S, G = any> extends ActionContext<S, any> {
  getters: G;
  rootGetters: RootGetters;
}
