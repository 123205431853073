export const deepEqual = (a: unknown, b: unknown): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const omit = <T>(src: T, fields: string | string[]): Partial<T> => {
  const result = { ...src };
  for (const field of typeof fields === 'string' ? [fields] : fields) {
    // skipcq: JS-0320
    delete result[field];
  }
  return result;
};

export const pick = <T>(src: T, fields: string | string[]): Partial<T> => {
  const result = {};
  for (const key of typeof fields === 'string' ? [fields] : fields) {
    result[key] = src[key];
  }
  return result;
};
