export * from './apiConfig';
export * from './emailPattern';
export * from './urlPattern';
export * from './leaseSteppingId';
export * from './purchaseOptionsIds';
export * from './date';
export * from './users';
export * from './titles';
export * from './searchInputDelay';
export * from './email';

export const DONGLE_UPDATE_TYPES_EMERGENCY_CODE_GENERATED = 'Emergency code generated';
