import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#93C856',
        success: '#8DEFC1',
        warning: '#F1CF3B',
        error: '#FB5F62',
        info: '#4870BB',
        disabled: '#DBDBDB',
        // red: '',
        // gray: '',
      },
    },
  },
});
