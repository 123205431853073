import { captureMessage, SeverityLevel, withScope } from '@sentry/vue';
import { generate } from 'shortid';

export class AppLogger {
  prefix: string;

  constructor(serviceName: string) {
    const id = generate();
    this.prefix = `${serviceName} > ${id}`;
  }

  public static consoleWarn(message: string): void {
    console.warn(message);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static consoleError(message: string, error?: Error | any[]): void {
    console.error(message, error ? error : '');
  }

  public static consoleLog(message: string): void {
    console.log(message); // skipcq: JS-0002
  }

  public static showErrorOnPage(errorString: string): void {
    // We will immediately see error from console during E2E test
    document.getElementsByTagName(
      'body',
    )[0].innerHTML = `<h1 style="text-align: center; color: red;">ERROR FROM BROWSER CONSOLE:<br><br>${errorString}</h1>`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- skipcq: JS-0323
  debug(message: string, details?: { [key: string]: any } | string): void {
    AppLogger.consoleLog(this.getPrefixedMessage(message, details));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- skipcq: JS-0323
  warn(message: string, details?: { [key: string]: any }): void {
    const prefixedMessage = this.getPrefixedMessage(message, details);
    AppLogger.consoleWarn(prefixedMessage);
    this.captureMessage({
      severityLevel: 'warning',
      message,
      details,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- skipcq: JS-0323
  error(message: string, error?: Error | any[], details?: { [key: string]: any } | string, printError = true): void {
    const prefixedMessage = this.getPrefixedMessage(message, details);
    if (printError) {
      AppLogger.consoleError(prefixedMessage, error);
      // AppLogger.showErrorOnPage(`${prefixedMessage}\n${error ? error : ''}`);
    }
    this.captureMessage({ severityLevel: 'error', message, details, error });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- skipcq: JS-0323
  log(message: string, details?: { [key: string]: any }): void {
    const prefixedMessage = this.getPrefixedMessage(message, details);
    AppLogger.consoleLog(prefixedMessage);
    this.captureMessage({
      severityLevel: 'log',
      message,
      details,
    });
  }

  captureStoreError(actionName: string, error: unknown, details?: unknown): void {
    // this.error(`${actionName} error`, error, details, isTestEnv());
    this.error(`${actionName} error`, error as Error, details as { [key: string]: unknown }, false);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- skipcq: JS-0323
  private getPrefixedMessage(message: string, details?: { [key: string]: any } | string): string {
    return `${this.prefix} | ${message || ''}${details ? `\n${JSON.stringify(details, null, 2)}` : ''}`;
  }

  private captureMessage(params: {
    severityLevel: SeverityLevel;
    message: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: Error | any[];
    details?:
      | {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- skipcq: JS-0323
          [key: string]: any;
        }
      | string;
  }) {
    if (!process.env.VUE_APP_SENTRY_DSN) {
      return;
    }
    withScope(scope => {
      scope.setTag('frontend', 'vue');
      scope.setExtra('message', params.message);
      scope.setExtra('serviceID', this.prefix);
      scope.setExtra('params', params.details ? JSON.stringify(params.details, null, 2) : '');
      if (params.error) {
        scope.setExtra('originalError', params.error);
      }
      scope.setLevel(params.severityLevel);
      captureMessage(params.message);
    });
  }
}
