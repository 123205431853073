import { CreateReleaseDto, ReleaseDto, UpdateReleaseDto } from '@/castapi';
import Vue from 'vue';
import { AppLogger } from '@/logger';
import { getErrorMessage, getReleasesApi } from '@/castapi/helpers';
import { IActionParams } from '@/store/modules/index';

const logger = new AppLogger('adminReleases state');

interface IAdminReleasesState {
  releases: ReleaseDto[];
  releasesLoading: boolean;
  releasesLoadError: null | string;
  releaseSaving: boolean;
  releaseSaveError: null | string;
}

const initialState = (): IAdminReleasesState => ({
  releases: [],
  releasesLoading: false,
  releasesLoadError: null,
  releaseSaving: false,
  releaseSaveError: null,
});

type ActionParams = IActionParams<IAdminReleasesState>;

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    RELEASES_LOADING(state: IAdminReleasesState): void {
      state.releasesLoadError = null;
      state.releasesLoading = true;
    },
    RELEASES_LOADED(state: IAdminReleasesState, payload: ReleaseDto[]): void {
      state.releases = payload;
      state.releasesLoading = false;
    },
    RELEASES_LOAD_ERROR(state: IAdminReleasesState, error: Error): void {
      state.releasesLoadError = getErrorMessage(error);
      state.releasesLoading = false;
    },
    RELEASE_ADD_IN_PROGRESS(state: IAdminReleasesState): void {
      state.releaseSaving = true;
      state.releaseSaveError = null;
    },
    RELEASE_ADD_SUCCESS(state: IAdminReleasesState, payload: ReleaseDto): void {
      state.releases.push(payload);
      state.releaseSaving = false;
    },
    RELEASE_ADD_ERROR(state: IAdminReleasesState, error: Error): void {
      state.releaseSaving = false;
      state.releaseSaveError = getErrorMessage(error);
    },
    RELEASE_UPDATE_IN_PROGRESS(state: IAdminReleasesState): void {
      state.releaseSaving = true;
      state.releaseSaveError = null;
    },
    RELEASE_UPDATE_SUCCESS(state: IAdminReleasesState, payload: ReleaseDto): void {
      const ind = state.releases.findIndex(item => item.id === payload.id);
      Vue.set(state.releases, ind, payload);
      state.releaseSaving = false;
    },
    RELEASE_UPDATE_ERROR(state: IAdminReleasesState, error: Error): void {
      state.releaseSaving = false;
      state.releaseSaveError = getErrorMessage(error);
    },
    RELEASE_DELETE_IN_PROGRESS(state: IAdminReleasesState): void {
      state.releaseSaving = true;
      state.releaseSaveError = null;
    },
    RELEASE_DELETE_SUCCESS(state: IAdminReleasesState, id: number): void {
      const index = state.releases.findIndex(item => item.id === id);
      state.releases.splice(index, 1);
      state.releaseSaving = false;
    },
    RELEASE_DELETE_ERROR(state: IAdminReleasesState, error: Error): void {
      state.releaseSaving = false;
      state.releaseSaveError = getErrorMessage(error);
    },
    RESET_SAVING_STATUS(state: IAdminReleasesState): void {
      state.releaseSaving = false;
      state.releaseSaveError = null;
    },
    RESET_STATE(state: IAdminReleasesState): void {
      const initState = initialState();
      Object.keys(initState).forEach((key: string) => {
        state[key] = initState[key];
      });
    },
  },
  actions: {
    async loadReleases({ commit, rootGetters }: ActionParams): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('RELEASES_LOADING');
        const response = await getReleasesApi(accessToken).releaseControllerFindAll();
        commit('RELEASES_LOADED', response.data);
      } catch (error) {
        commit('RELEASES_LOAD_ERROR', error);
        logger.captureStoreError('loadReleases', error);
      }
    },
    async addRelease({ commit, dispatch, rootGetters }: ActionParams, data: CreateReleaseDto): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      commit('RELEASE_ADD_IN_PROGRESS');
      try {
        const response = await getReleasesApi(accessToken).releaseControllerCreate(data);
        commit('RELEASE_ADD_SUCCESS', response.data);
        await dispatch('loadReleases');
      } catch (error) {
        commit('RELEASE_ADD_ERROR', error);
        logger.captureStoreError('addRelease', error, { data });
      }
    },
    async updateRelease({ commit, dispatch, rootGetters }: ActionParams, data: UpdateReleaseDto): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      commit('RELEASE_UPDATE_IN_PROGRESS');
      try {
        await getReleasesApi(accessToken).releaseControllerUpdate(data.id, data);
        commit('RELEASE_UPDATE_SUCCESS', data);
        await dispatch('loadReleases');
      } catch (error) {
        commit('RELEASE_UPDATE_ERROR', error);
        logger.captureStoreError('updateRelease', error, { data });
      }
    },
    async removeRelease({ commit, dispatch, rootGetters }: ActionParams, id: number): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      commit('RELEASE_DELETE_IN_PROGRESS');
      try {
        await getReleasesApi(accessToken).releaseControllerRemove(id);
        commit('RELEASE_DELETE_SUCCESS', id);
        await dispatch('loadReleases');
      } catch (error) {
        commit('RELEASE_DELETE_ERROR', error);
        logger.captureStoreError('removeRelease', error, { id });
      }
    },
    resetSavingStatus({ commit }: ActionParams): void {
      commit('RESET_SAVING_STATUS');
    },
    resetState({ commit }: ActionParams): void {
      commit('RESET_STATE');
    },
  },
  getters: {
    releases: (state: IAdminReleasesState): ReleaseDto[] => state.releases,
    releasesLoading: (state: IAdminReleasesState): boolean => state.releasesLoading,
    releasesLoadError: (state: IAdminReleasesState): string | null => state.releasesLoadError,
    releaseSaving: (state: IAdminReleasesState): boolean => state.releaseSaving,
    releaseSaveError: (state: IAdminReleasesState): string | null => state.releaseSaveError,
  },
};
