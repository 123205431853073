import {
  AddressDto,
  CreateOrderDto,
  DiscountDto,
  ExpireDateResponseDto,
  HttpExceptionDto,
  OrderDto,
  OrderItemDto,
  OrderWideDto,
  ProductDto,
  ProductWideDto,
  RenewUpgradePossibilityDto,
  StripeSessionDto,
} from '@/castapi';
import { loadStripe } from '@stripe/stripe-js/pure';
import { getDiscountApi, getErrorMessage, getOrdersApi, getProductsApi, isKnownError } from '@/castapi/helpers';
import { AppLogger } from '@/logger';
import { AxiosResponse } from 'axios';
import { IActionParams, RootGetters } from '@/store/modules';
import { Commit, Dispatch } from 'vuex';

const logger = new AppLogger('OrderState');

export interface RenewUpgradePossibilityDtoWithNested
  extends Omit<RenewUpgradePossibilityDto, 'upgradeProducts' | 'renewProducts'> {
  upgradeProducts: ProductDto[];
  renewProducts: ProductDto[];
}

export interface CartItemData extends OrderItemDto, ProductDto {}

export type CreateOrderDongleRefAndAddressesPart = {
  existingDongleRef?: number;
  shippingAddress?:
    | (Partial<AddressDto> & {
        firstName: string | undefined;
        lastName: string | undefined;
        phoneNumber: string | undefined;
        companyName: string;
        organizationRef: number;
        userRef: number;
      })
    | null;
  billingAddress?:
    | (Partial<AddressDto> & {
        firstName: string | undefined;
        lastName: string | undefined;
      })
    | null;
  dealerPurchaseRequest?: boolean;
};

export interface IOrderState {
  order: Partial<OrderWideDto> | null;
  finishedOrder: OrderDto | null;
  finishedOrderDiscount: DiscountDto | null;
  discount: DiscountDto | null;
  error: null | string;
  isLoading: boolean;
  checkoutLoading: boolean;
  renewUpgrade: RenewUpgradePossibilityDtoWithNested;
  productsMap: Record<string, ProductWideDto[]>;
  productLoadError: null | string;
  productsLoading: boolean;
  shopProducts: ProductWideDto[];
  expireDateLoading: boolean;
  expireDateLoadError: null | string;
  expireDate: null | ExpireDateResponseDto;
}

// TODO: Wrong logic, need to fix. Case when corresponding product is not loaded and so product can not be found
const getOrderItemProduct = (orderItem: OrderItemDto, allProducts: ProductDto[]): CartItemData => {
  const product = allProducts.find(p => p.productId === orderItem.itemProductRef);
  // if (!product) {
  //   throw new Error(`Can not find product with id: ${orderItem.itemProductRef}`);
  // }
  return { ...(product || ({} as ProductDto)), ...orderItem }; // temp fix
};

const getOrderValue = <T>(...values: T[]): T => {
  for (const v of values) {
    if (v) {
      return v;
    }
  }
  return null as unknown as T;
};

// Plan: Solve problem - all fields can contains only value or undefined, but we trying to assign null!
const getCreateOrderDto = (
  state: IOrderState,
  { isAdmin, organization: org, user },
  data: CreateOrderDongleRefAndAddressesPart | undefined,
): CreateOrderDto => {
  const { shippingAddress, billingAddress, existingDongleRef } = { ...data };
  const order = state.order;
  if (!order) {
    throw new Error('Order is not defined');
  }
  return {
    orderId: getOrderValue(order.orderId),
    firstName: getOrderValue(shippingAddress?.firstName, order.firstName, !isAdmin && user.firstName),
    lastName: getOrderValue(shippingAddress?.lastName, order.lastName, !isAdmin && user.lastName),
    address1: getOrderValue(shippingAddress?.address1, order.address1),
    apt: getOrderValue(shippingAddress?.apt, order.apt),
    city: getOrderValue(shippingAddress?.city, order.city),
    province: getOrderValue(shippingAddress?.province, order.province),
    countryRef: getOrderValue(shippingAddress?.countryRef, order.countryRef),
    postalCode: getOrderValue(shippingAddress?.postalCode, order.postalCode),
    phoneNumber: getOrderValue(shippingAddress?.phoneNumber, order.phoneNumber, org?.organizationPhoneNumber),
    companyName: getOrderValue(shippingAddress?.companyName, order.companyName, org?.organizationName),
    organizationRef: getOrderValue(shippingAddress?.organizationRef, order.organizationRef, org?.organizationId),
    billingAddress1: getOrderValue(billingAddress?.address1, order.billingAddress1) as string,
    billingApt: getOrderValue(billingAddress?.apt, order.billingApt),
    billingCity: getOrderValue(billingAddress?.city, order.billingCity) as string,
    billingPostalCode: getOrderValue(billingAddress?.postalCode, order.billingPostalCode) as string,
    billingCountryRef: getOrderValue(billingAddress?.countryRef, order.billingCountryRef),
    billingProvince: getOrderValue(billingAddress?.province, order.billingProvince),
    billingFirstName: getOrderValue(billingAddress?.firstName, order.billingFirstName, !isAdmin && user.firstName),
    billingLastName: getOrderValue(billingAddress?.lastName, order.billingLastName, !isAdmin && user.lastName),
    vatNumber: getOrderValue(billingAddress?.vatNumber, order.vatNumber),
    orderItems: order.orderItems as OrderItemDto[],
    refPromo: getOrderValue(state.discount?.discountId, order.refPromo),
    userRef: getOrderValue(shippingAddress?.userRef, order.userRef, !isAdmin && user?.userId),
    notes: getOrderValue(order.notes),
    isRenewUpgrade: Boolean(existingDongleRef || order.existingDongleRef),
    existingDongleRef: existingDongleRef !== undefined ? existingDongleRef : order.existingDongleRef || undefined,
    dealerPurchaseRequest: Boolean(data?.dealerPurchaseRequest || order?.dealerPurchaseRequest),
  };
};

const validateOrder = (order: Partial<OrderWideDto> | null): boolean =>
  Boolean(
    order?.orderItems?.length &&
      order.firstName &&
      order.lastName &&
      order.userRef &&
      order.billingCountryRef &&
      order.billingProvince &&
      order.companyName &&
      order.organizationRef &&
      (order.isRenewUpgrade
        ? order.existingDongleRef
        : order.address1 && order.city && order.province && order.countryRef && order.postalCode),
  );

const initialState: () => IOrderState = () => ({
  order: {
    orderItems: [],
    orderId: undefined,
  },
  finishedOrder: null,
  finishedOrderDiscount: null,
  discount: null,
  error: null,
  isLoading: false,
  checkoutLoading: false,
  renewUpgrade: {
    renewRequired: false,
    isTooNewForRenew: false,
    isTooOldForRenew: false,
    renewalsAvailable: 0,
    expireDateAfterMaxPossibleRenew: '',
    isUpgradePossible: false,
    renewProducts: [],
    upgradeProducts: [],
  },
  productsMap: {},
  productLoadError: null,
  productsLoading: false,
  shopProducts: [],
  expireDateLoading: false,
  expireDateLoadError: null,
  expireDate: null,
});

export interface Getters {
  orderId: number | undefined;
  allProductsForFinishedOrder: ProductDto[];
  allProducts: ProductWideDto[];
  cartProducts: CartItemData[];
  currentDongleRenewUpgradeOptions: RenewUpgradePossibilityDtoWithNested;
  shopProducts: ProductWideDto[];
  orderProductsPrice: number;
  tax: number;
  shipping: number;
  orderDiscountTotal: number;
  discount: DiscountDto | null;
}

type ActionParams = IActionParams<IOrderState, Getters>;

export default class {
  state = initialState;
  mutations = {
    LOADING(state: IOrderState): void {
      state.isLoading = true;
      state.error = null;
    },
    CHECKOUT_LOADING(state: IOrderState): void {
      state.checkoutLoading = true;
    },
    CHECKOUT_LOADED(state: IOrderState): void {
      state.checkoutLoading = false;
    },
    ERROR(state: IOrderState, payload: Error): void {
      state.isLoading = false;
      state.error = getErrorMessage(payload);
    },
    LOADED(state: IOrderState): void {
      state.isLoading = false;
    },
    ORDER_LOADED(state: IOrderState, order: OrderWideDto): void {
      state.isLoading = false;
      state.order = { ...state.order, ...order };
    },
    ORDER_CHANGED(state: IOrderState): void {
      state.isLoading = false;
      state.order = null;
    },
    CLEAR_ORDER(state: IOrderState): void {
      state.order = initialState().order;
      state.discount = initialState().discount;
      state.error = initialState().error;
    },
    CLEAR_FINISHED_ORDER(state: IOrderState): void {
      state.finishedOrder = initialState().finishedOrder;
      state.finishedOrderDiscount = initialState().finishedOrderDiscount;
    },
    FINISHED_ORDER_LOADED(state: IOrderState, order: OrderDto): void {
      state.isLoading = false;
      state.error = null;
      state.finishedOrder = order;
      state.finishedOrderDiscount = null;
    },
    FINISHED_ORDER_DISCOUNT_LOADED(state: IOrderState, discount: DiscountDto): void {
      state.isLoading = false;
      state.error = null;
      state.finishedOrderDiscount = discount;
    },
    DISCOUNT_LOADED(state: IOrderState, discount: DiscountDto): void {
      if (!state.order) {
        throw new Error('Order is not defined');
      }
      state.isLoading = false;
      state.discount = discount;
      state.order.refPromo = discount?.discountId || undefined;
    },
    DROP_DISCOUNT(state: IOrderState): void {
      if (!state.order) {
        throw new Error('Order is not defined');
      }
      state.discount = null;
      state.order.refPromo = undefined;
    },
    REMOVE_PRODUCT_FROM_CART(state: IOrderState, product: ProductWideDto): void {
      if (!state.order) {
        throw new Error('Order is not defined');
      }
      const orderItemDtos = state.order.orderItems;
      if (!orderItemDtos) {
        throw new Error('Order items are not defined');
      }
      const index = orderItemDtos.findIndex((item: OrderItemDto) => item.itemProductRef === product.productId);
      orderItemDtos.splice(index, 1);
    },
    ADD_PRODUCT_TO_CART(state: IOrderState, addedProduct: ProductWideDto): void {
      if (!state.order) {
        throw new Error('Order is not defined');
      }
      const orderItems = state.order.orderItems;
      if (!orderItems) {
        throw new Error('Order items are not defined');
      }
      const cartItem = orderItems.find((item: OrderItemDto) => item.itemProductRef === addedProduct.productId);
      if (!cartItem) {
        orderItems.push({
          itemCount: 1,
          itemId: undefined,
          itemOrderRef: undefined,
          itemPrice: addedProduct.productPrice,
          itemProductRef: addedProduct.productId,
          itemDiscount: 0,
          itemTotalPrice: addedProduct.productPrice,
        });
      } else {
        cartItem.itemCount++;
      }
    },
    CHANGE_PRODUCT_COUNT(state: IOrderState, { itemCount, productId }: { itemCount: number; productId: number }): void {
      if (!state.order) {
        throw new Error('Order is not defined');
      }
      const orderItems = state.order.orderItems;
      if (!orderItems) {
        throw new Error('Order items are not defined');
      }
      const cartItem = orderItems.find((item: OrderItemDto) => item.itemProductRef === productId);
      if (!cartItem) {
        throw new Error(`Can not find product with id: ${productId}`);
      }
      cartItem.itemCount = itemCount;
    },
    PRODUCTS_LOADED(state: IOrderState, productsMap: Record<string, ProductWideDto[]>): void {
      state.productsMap = productsMap;
      state.shopProducts = Object.keys(productsMap).reduce((acc, curr): ProductWideDto[] => {
        const reduced = productsMap[curr].reduce(
          (poAcc: ProductWideDto[], currProduct: ProductWideDto): ProductWideDto[] => {
            const mapped = currProduct.productOptions.map(option => ({ ...currProduct, ...option }));
            return [...poAcc, ...mapped];
          },
          [],
        );
        return [...acc, ...reduced];
      }, new Array<ProductWideDto>());
      state.productsLoading = false;
    },
    PRODUCTS_LOADING(state: IOrderState): void {
      state.productLoadError = null;
      state.productsLoading = true;
    },
    PRODUCTS_LOAD_ERROR(state: IOrderState, error: Error): void {
      state.productLoadError = getErrorMessage(error);
      state.productsLoading = false;
    },
    SELECT_PRODUCT(state: IOrderState, product: ProductWideDto | CartItemData): void {
      if (!state.order) {
        throw new Error('Order is not defined');
      }
      state.order.orderItems = [
        {
          itemCount: (product as CartItemData).itemCount || 1,
          itemId: undefined,
          itemOrderRef: undefined,
          itemPrice: 0,
          itemProductRef: product.productId,
          itemDiscount: 0,
          itemTotalPrice: 0,
        },
      ];
    },
    RENEW_UPGRADE_PRODUCTS_LOADING(state: IOrderState): void {
      state.isLoading = true;
      state.error = null;
    },
    RENEW_UPGRADE_PRODUCTS_LOADED(state: IOrderState, payload: RenewUpgradePossibilityDtoWithNested): void {
      state.isLoading = false;
      state.renewUpgrade = payload;
    },
    EXPIRE_DATE_LOADING(state: IOrderState): void {
      state.expireDateLoading = true;
      state.expireDate = null;
      state.expireDateLoadError = null;
    },
    EXPIRE_DATE_LOADED(state: IOrderState, expireDate: ExpireDateResponseDto): void {
      state.expireDateLoading = false;
      state.expireDate = expireDate;
    },
    EXPIRE_DATE_LOAD_ERROR(state: IOrderState, error: Error): void {
      state.expireDateLoading = false;
      state.expireDateLoadError = getErrorMessage(error);
    },
  };
  actions = {
    async redirectToCheckout({
      state,
      commit,
      rootGetters,
    }: {
      state: IOrderState;
      commit: Commit;
      rootGetters: RootGetters;
    }): Promise<void> {
      const orderId = state.order?.orderId;
      try {
        commit('CHECKOUT_LOADING');
        const token = rootGetters['login/accessToken'];
        const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY as string);
        if (!stripe) {
          throw new Error('Can not init `Stripe`');
        }
        const response = await getOrdersApi(token).ordersControllerCreateSession({ orderId });
        const session = response.data as StripeSessionDto;
        commit('CLEAR_ORDER');
        commit('CHECKOUT_LOADED');
        await stripe.redirectToCheckout({ sessionId: session?.id });
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('redirectToCheckout', error);
      }
    },

    async getOrder({ commit, dispatch, rootGetters }: ActionParams, orderId: number): Promise<void> {
      commit('LOADING');
      try {
        const response = await getOrdersApi(rootGetters['login/accessToken']).ordersControllerGetOrder(orderId);
        await dispatch('orderLoaded', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getOrder', { orderId });
      }
    },

    async syncOrder(
      { commit, state, rootGetters }: { commit: Commit; state: IOrderState; rootGetters: RootGetters },
      data?: CreateOrderDongleRefAndAddressesPart,
    ): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      // Note: User can add product to cart without login, but in this case we don't need to send order to server
      if (!accessToken) {
        return;
      }
      const isAdmin = rootGetters['login/isAdmin'];
      const organization = isAdmin
        ? rootGetters['adminOrganizations/currentOrganization']
        : rootGetters['organizations/organization'];
      const user = isAdmin ? rootGetters['adminOrganizations/currentOrganizationMainUser'] : rootGetters['login/user'];
      try {
        const orderToSync = getCreateOrderDto(state, { isAdmin, organization, user }, data);
        commit('LOADING');
        const response = await getOrdersApi(accessToken).ordersControllerAddOrder(orderToSync);
        commit('ORDER_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('syncOrder', error, { data });
      }
    },

    async orderLoaded(
      { commit, dispatch, getters }: { commit: Commit; dispatch: Dispatch; getters: Getters },
      order: OrderWideDto,
    ): Promise<void> {
      if (order.refPromo && !getters.discount) {
        await dispatch('loadDiscount', order.refPromo);
      }
      if (order.existingDongleRef) {
        await dispatch('getDongleRenewUpgradeOptions', order.existingDongleRef);
      }
      commit('ORDER_LOADED', order);
    },

    async getFinishedOrder({ commit, dispatch, rootGetters }: ActionParams, orderId: number): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      if (!accessToken) {
        return;
      }
      commit('LOADING');
      try {
        const response = await getOrdersApi(accessToken).ordersControllerGetUserOrder(orderId);
        commit('FINISHED_ORDER_LOADED', response.data);
        const discountId = (response.data as OrderDto)?.refPromo;
        if (discountId) {
          await dispatch('getFinishedOrderDiscount', discountId);
        }
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getFinishedOrder', error, { orderId });
      }
    },

    async getFinishedOrderDiscount({ commit, rootGetters }: ActionParams, discountId: number): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      if (!accessToken) {
        return;
      }
      try {
        commit('LOADING');
        const response = await getDiscountApi(accessToken).discountsControllerFindOne(discountId);
        commit('FINISHED_ORDER_DISCOUNT_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getFinishedOrderDiscount', error, { discountId });
      }
    },

    async applyDiscount({ state, commit, dispatch, rootGetters }: ActionParams, discountCode: string): Promise<void> {
      if (!discountCode) {
        return;
      }
      const accessToken = rootGetters['login/accessToken'];
      if (!accessToken) {
        return;
      }
      try {
        commit('DROP_DISCOUNT');
        commit('LOADING');
        const order = state.order as OrderWideDto;
        if (!order) {
          throw new Error('Order is not defined');
        }
        const orderId: number = order.orderId;
        const response = await getDiscountApi(accessToken).discountsControllerFindByCode(
          discountCode,
          orderId,
          order.dealerPurchaseRequest,
        );
        await dispatch('discountLoaded', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('applyDiscount', error, { discountCode });
      }
    },

    async discountLoaded({ state, commit, dispatch }: ActionParams, discount: DiscountDto): Promise<void> {
      commit('DISCOUNT_LOADED', discount);
      if (state.order) {
        await dispatch('syncOrder');
      }
    },

    async dropDiscount({ state, commit, dispatch }: ActionParams): Promise<void> {
      commit('DROP_DISCOUNT');
      if (state.order) {
        await dispatch('syncOrder');
      }
    },

    async loadDiscount({ commit, rootGetters }: ActionParams, discountId: number): Promise<void> {
      const accessToken = rootGetters['login/accessToken'];
      if (!accessToken) {
        return;
      }
      try {
        commit('LOADING');
        const response = await getDiscountApi(accessToken).discountsControllerFindOne(discountId);
        commit('DISCOUNT_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('loadDiscount', error, { discountId });
      }
    },

    async changeOrderNotes({ state, commit, rootGetters }: ActionParams, notes: string): Promise<void> {
      if (!state.order) {
        return;
      }
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('LOADING');
        if (!state.order) {
          throw new Error('Order is not defined');
        }
        const response = await getOrdersApi(accessToken).ordersControllerChangeOrderNotes({
          orderId: (state.order as OrderWideDto).orderId,
          notes,
        });
        commit('ORDER_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('changeOrderNotes', error, { notes });
      }
    },

    async markAsPaid(
      { state, commit, rootGetters }: ActionParams,
      data: { isFulfilled: boolean; paymentMethod: string; notes: string },
    ): Promise<void> {
      if (!state.order) {
        return;
      }
      const { isFulfilled, paymentMethod, notes } = data;
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('LOADING');
        if (!state.order) {
          throw new Error('Order is not defined');
        }
        const response = await getOrdersApi(accessToken).ordersControllerMarkOrderAsPaid({
          orderId: (state.order as OrderWideDto).orderId,
          isFulfilled,
          paymentMethod,
          notes,
        });
        commit('ORDER_CHANGED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('markAsPaid', error, { isFulfilled, paymentMethod, notes });
      }
    },

    async updateFulfilmentStatus({ state, commit, rootGetters }: ActionParams, isFulfilled: boolean): Promise<void> {
      if (!state.order) {
        return;
      }
      try {
        const accessToken = rootGetters['login/accessToken'];

        commit('LOADING');
        if (!state.order) {
          throw new Error('Order is not defined');
        }
        const response = await getOrdersApi(accessToken).ordersControllerUpdateOrderFulfilmentStatus({
          orderId: (state.order as OrderWideDto).orderId,
          isFulfilled,
        });
        commit('ORDER_CHANGED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('markAsPaid', error, { isFulfilled });
      }
    },

    async deleteOrder({ state, commit, rootGetters }: ActionParams): Promise<void> {
      if (!state.order) {
        return;
      }
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('LOADING');
        const orderId = state.order.orderId;
        const response = await getOrdersApi(accessToken).ordersControllerDeleteOrder({ orderId });
        commit('ORDER_CHANGED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('deleteOrder', error, { orderId: state.order?.orderId });
      }
    },

    async voidOrder({ state, commit, rootGetters }: ActionParams): Promise<void> {
      if (!state.order) {
        return;
      }
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('LOADING');
        const orderId = state.order.orderId;
        const response = await getOrdersApi(accessToken).ordersControllerVoidOrder({ orderId });
        commit('ORDER_CHANGED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('voidOrder', error, { orderId: state.order?.orderId });
      }
    },

    async removePaymentIntentDataFromOrder({ commit, rootGetters }: ActionParams, orderId: number): Promise<void> {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('LOADING');
        const response = await getOrdersApi(accessToken).ordersControllerRemovePaymentIntentDataFromOrder({ orderId });
        commit('ORDER_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('removePaymentIntentDataFromOrder', error, { orderId });
      }
    },

    async removeProductFromCart({ commit, dispatch }: ActionParams, product: ProductWideDto): Promise<void> {
      commit('REMOVE_PRODUCT_FROM_CART', product);
      await dispatch('syncOrder');
    },

    async getProductsFromApi({ commit, dispatch, rootGetters }: ActionParams, showOutdated = false): Promise<void> {
      try {
        commit('PRODUCTS_LOADING');
        const response = showOutdated
          ? await getProductsApi(rootGetters['login/accessToken']).productsControllerCumulativeProductsWithOutdated()
          : await getProductsApi().productsControllerCumulativeProducts();
        commit('PRODUCTS_LOADED', response.data);
        await dispatch('getUserUnfinishedOrder');
      } catch (error) {
        commit('PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('getProductsFromApi', error);
      }
    },

    async getUserUnfinishedOrder(
      { commit, dispatch, getters, rootGetters }: ActionParams,
      userId?: number,
    ): Promise<void> {
      try {
        const token = rootGetters['login/accessToken'];
        if (!token || getters.cartProducts.length) {
          return;
        }
        if (!getters.allProducts.length) {
          await dispatch('getProductsFromApi', true);
          return;
        }
        commit('LOADING');
        let response: AxiosResponse<OrderDto>;
        if (userId) {
          response = await getOrdersApi(token).ordersControllerGetUserUnfinishedOrder(userId);
        } else {
          if (rootGetters['login/isAdmin'] === undefined) {
            throw new Error('Need to check `isAdmin()` before call `getUnfinishedOrder()`');
          }
          if (rootGetters['login/isAdmin']) {
            throw new Error('`getUnfinishedOrder()` can be called only for customer');
          }
          response = await getOrdersApi(token).ordersControllerGetUnfinishedOrder();
        }
        await dispatch('orderLoaded', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getUserUnfinishedOrder', error, { userId });
      }
    },

    async addProductToCart({ state, commit, dispatch }: ActionParams, addedProduct: ProductWideDto): Promise<void> {
      if (state.order?.existingDongleRef) {
        commit('CLEAR_ORDER');
      }
      commit('ADD_PRODUCT_TO_CART', addedProduct);
      await dispatch('syncOrder');
    },

    async changeProductCount({ commit, dispatch }: ActionParams, cartItem: CartItemData): Promise<void> {
      commit('CHANGE_PRODUCT_COUNT', { itemCount: cartItem.itemCount, productId: cartItem.productId });
      await dispatch('syncOrder');
    },

    async getDongleRenewUpgradeOptions({ commit, rootGetters }: ActionParams, dongleId: number): Promise<void> {
      const token = rootGetters['login/accessToken'];
      commit('RENEW_UPGRADE_PRODUCTS_LOADING');
      try {
        const response = await getProductsApi(token).productsControllerGetRenewUpgradeProducts(dongleId);
        commit('RENEW_UPGRADE_PRODUCTS_LOADED', response.data);
      } catch (error) {
        commit('ERROR', error);
        // Plan: check if it is a correct situation?
        if (isKnownError(error as HttpExceptionDto, 403, 'No permissions to fetch dongle info')) {
          return;
        }
        logger.captureStoreError('getDongleRenewUpgradeOptions', error, { dongleId });
      }
    },

    async selectProduct({ commit, dispatch, rootGetters }: ActionParams, product: ProductWideDto): Promise<void> {
      commit('SELECT_PRODUCT', product);
      const isAdmin = rootGetters['login/isAdmin'];
      const existingDongleRef = isAdmin
        ? rootGetters['adminDongles/currentDongleId']
        : rootGetters['dongles/currentDongleId'];
      await dispatch('syncOrder', { existingDongleRef });
    },

    async testCheckout({ state, commit, rootGetters }: ActionParams): Promise<void> {
      if (!state.order) {
        throw new Error('Order is not defined');
      }
      const orderId = state.order.orderId;
      try {
        commit('LOADING');
        const token = rootGetters['login/accessToken'];
        const response = await getOrdersApi(token).ordersControllerProcessTestOrder({ orderId });
        commit('CLEAR_ORDER');
        commit('LOADED');
        window.location.href = response.data;
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('testCheckout', error, { orderId });
      }
    },

    async getNewExpireDate(
      { commit, rootGetters }: ActionParams,
      {
        steppingRef,
        expiryDate,
        purchaseOption,
        duration,
        itemCount,
      }: { steppingRef: number; expiryDate: string; purchaseOption: number; duration: number; itemCount: number },
    ): Promise<void> {
      commit('EXPIRE_DATE_LOADING');
      try {
        const token = rootGetters['login/accessToken'];
        const response = await getProductsApi(token).productsControllerGetNewExpireDate(
          steppingRef,
          expiryDate,
          purchaseOption,
          duration,
          itemCount,
        );
        commit('EXPIRE_DATE_LOADED', response.data);
      } catch (error) {
        commit('EXPIRE_DATE_LOAD_ERROR', error);
        logger.captureStoreError('getNewExpireDate', error, {
          steppingRef,
          expiryDate,
          purchaseOption,
          duration,
          itemCount,
        });
      }
    },
  };
  // noinspection JSUnusedGlobalSymbols
  getters = {
    order: (state: IOrderState): Partial<OrderWideDto> | null => state.order,
    orderId: (state: IOrderState): number | undefined => state.order?.orderId,
    orderProductsPrice: (state: IOrderState): number =>
      state.order?.orderItems?.reduce(
        (acc: number, curr: OrderItemDto) => acc + curr.itemCount * (curr.itemPrice || 0),
        0,
      ) || 0,
    orderProductsTotal: (state: IOrderState): number =>
      state.order?.orderItems?.reduce((acc, curr) => acc + curr.itemCount * (curr.itemTotalPrice || 0), 0) || 0,
    orderDiscountTotal: (state: IOrderState): number =>
      state.order?.orderItems?.reduce((acc, curr) => acc + curr.itemCount * (curr.itemDiscount || 0), 0) || 0,
    finishedOrder: (state: IOrderState): OrderDto | null => state.finishedOrder,
    finishedOrderProducts: (state: IOrderState, getters: Getters): CartItemData[] =>
      state.finishedOrder?.orderItems?.map(item => getOrderItemProduct(item, getters.allProductsForFinishedOrder)) ||
      [],
    finishedOrderProductsTotal: (state: IOrderState): number =>
      state.finishedOrder?.orderItems?.reduce((acc, curr) => acc + curr.itemCount * (curr.itemTotalPrice || 0), 0) || 0,
    finishedOrderProductsPrice: (state: IOrderState): number =>
      state.finishedOrder?.orderItems?.reduce((acc, curr) => acc + curr.itemCount * (curr.itemPrice || 0), 0) || 0,
    isFinishedOrderRenewUpgrade: (state: IOrderState): boolean => Boolean(state.finishedOrder?.isRenewUpgrade),
    isFinishedOrderShippable: (state: IOrderState): boolean => Boolean(state.finishedOrder?.shippable),
    discountPromoCode: (state: IOrderState): string | undefined => state.discount?.promoCode,
    finishedOrderDiscountPromoCode: (state: IOrderState): string | undefined => state.finishedOrderDiscount?.promoCode,
    discount: (state: IOrderState): DiscountDto | null => state.discount,
    isLoading: (state: IOrderState): boolean => state.isLoading,
    checkoutLoading: (state: IOrderState): boolean => state.checkoutLoading,
    error: (state: IOrderState): string | null => state.error,
    cartProducts: (state: IOrderState, getters: Getters): CartItemData[] =>
      state.order?.orderItems?.map(item => getOrderItemProduct(item, getters.allProducts)) || [],
    cartProductsCount: (_state: IOrderState, getters: Getters): number =>
      getters.cartProducts.reduce((acc: number, curr: CartItemData) => acc + curr.itemCount, 0),
    productsLoading: (state: IOrderState): boolean => state.productsLoading,
    productsLoadError: (state: IOrderState): string | null => state.productLoadError,
    productTypes: (state: IOrderState): string[] => (state?.productsMap ? Object.keys(state.productsMap) : []),
    productsMap: (state: IOrderState): Record<string, ProductWideDto[]> => state.productsMap,
    shopProducts: (state: IOrderState): ProductWideDto[] => state.shopProducts,
    currentDongleRenewUpgradeOptions: (state: IOrderState): ProductDto[] => [
      ...state.renewUpgrade.upgradeProducts,
      ...state.renewUpgrade.renewProducts,
    ],
    currentDongleRenewOptions: (state: IOrderState): ProductDto[] => state.renewUpgrade.renewProducts,
    currentDongleUpgradeOptions: (state: IOrderState): ProductDto[] => state.renewUpgrade.upgradeProducts,
    currentDongleRenewRequired: (state: IOrderState): boolean => state.renewUpgrade.renewRequired,
    currentDongleLicenseTooNewForRenew: (state: IOrderState): boolean => state.renewUpgrade.isTooNewForRenew,
    currentDongleLicenseTooOldForRenew: (state: IOrderState): boolean => state.renewUpgrade.isTooOldForRenew,
    currentDongleRenewalsAvailable: (state: IOrderState): number => state.renewUpgrade.renewalsAvailable,
    currentDongleExpireDateAfterMaxPossibleRenew: (state: IOrderState): string =>
      state.renewUpgrade.expireDateAfterMaxPossibleRenew,
    currentDongleUpgradePossibility: (state: IOrderState): boolean => state.renewUpgrade.isUpgradePossible,
    allProducts: (state: IOrderState, getters: Getters): RenewUpgradePossibilityDtoWithNested | ProductWideDto[] => {
      if (state.order?.existingDongleRef) {
        return getters.currentDongleRenewUpgradeOptions;
      }
      return state.shopProducts;
    },
    allProductsForFinishedOrder: (
      state: IOrderState,
      getters: Getters,
    ): RenewUpgradePossibilityDtoWithNested | ProductWideDto[] =>
      state.finishedOrder?.existingDongleRef ? getters.currentDongleRenewUpgradeOptions : getters.shopProducts,
    isOrderRenewUpgrade: (state: IOrderState): boolean => Boolean(state.order?.existingDongleRef),
    orderValid: (state: IOrderState): boolean => validateOrder(state.order),
    tax: (state: IOrderState): number => state.order?.tax || 0,
    shipping: (state: IOrderState): number => state.order?.shipping || 0,
    notes: (state: IOrderState): string | undefined => state.order?.notes,
    grandTotalPrice: (_state: IOrderState, getters: Getters): number =>
      getters.orderProductsPrice + getters.tax + getters.shipping - getters.orderDiscountTotal,
    expireDateLoading: (state: IOrderState): boolean => state.expireDateLoading,
    expireDateLoadError: (state: IOrderState): string | null => state.expireDateLoadError,
    expireDate: (state: IOrderState): ExpireDateResponseDto | null => state.expireDate,
    isOrderShippable: (_state: IOrderState, getters: Getters): boolean =>
      getters.cartProducts.some((product: CartItemData) => product.shippable),
  };
}
